.store-settings-color-picker {
    --color-picker-height: 45px;

    display: flex;
    align-items: center;
    gap: 15px;
}

.store-settings-color-frame-container {
    position: relative;
}

.store-settings-color-frame {
    height: var(--color-picker-height);
    aspect-ratio: 1;
    border: 1px solid #cccccc;
    border-radius: 7px;
    cursor: pointer;
}

.store-settings-color-description {
    display: flex;
    flex-direction: column;
    gap: 2px;
    background-color: #f6f6f4;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
}

.store-settings-color-description-title {
    color: #a8a9a5;
    font-size: 13px;
}

.store-settings-color-description-value {
    color: #2e2e2c;
    font-size: 13px;
}

.store-settings-color-picker-dialog-container {
    position: absolute;
    top: calc(var(--color-picker-height) + 10px);
    left: 0;
    z-index: 80;
}