.orders-table-row-view  {
    margin: auto;
    padding: 16px;
}

.orders-table-row-view-total,
.orders-table-row-view-items {
    padding: 5px;
}

.orders-table-row-view-date,
.orders-table-row-view-items,
.orders-table-row-view-total,
.orders-table-row-view-status,
.orders-table-row-view-view-in-shopify-action-text,
.mobile-orders-table-row-view-view-in-shopify-action-text {
    font-size: 12px;
}

.orders-table-row-view-view-in-shopify-action-text,
.mobile-orders-table-row-view-view-in-shopify-action-text {
    text-decoration: underline;
}

.orders-table-row-view-date-container {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 16px 16px 16px 10px !important;
}

.orders-table-row-view-expand-button-image {
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    flex-shrink: 0;
    transition: rotate 200ms ease-out;
}

.orders-table-row-view-expand-button-image:hover {
    background-color: #f2f2f2;
}


.orders-table-row-view-expand-button-image.expanded {
    rotate: -180deg;
}

.orders-table-row-view-items,
.orders-table-row-view-total {
    text-align: start;
    font-weight: 500;
}

.orders-table-row-view-status-container {
    padding: 16px 0;
}

.orders-table-row-view-status {
    padding: 3px 5px;
    text-transform: uppercase;
    border-radius: 6px;
    text-align: center;
    color: white;
}

.orders-table-row-view-actions {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 20px 5px;
}

.orders-table-row-view-no-actions {
    padding: 30px 5px;
}

.mobile-orders-table-row-view-view-in-shopify-action-image,
.orders-table-row-view-view-in-shopify-action-image {
    color: #007bff;
    flex-shrink: 0;
}

.orders-table-row-view-view-in-shopify-action-image {
    width: 15px;
    height: 15px;
}

.mobile-orders-table-row-view-view-in-shopify-action-image {
    width: 20px;
    height: 20px;
}

.mobile-orders-table-row-view {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.mobile-orders-table-row-view-content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.mobile-orders-table-row-view-details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.mobile-orders-table-row-view-created-at {
    font-size: 12px;
}

.mobile-orders-table-row-view-products-count-container {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.mobile-orders-table-row-view-total-products-count {
    font-size: 12px;
    font-weight: 500;
}

.mobile-orders-table-row-view-total-price-container {
    display: flex;
    align-items: center;
    gap: 15px;
}

.mobile-orders-table-row-view-total-price {
    font-size: 16px;
    font-weight: 500;
}

.orders-table-row-view-more-details-loader-container {
    width: 100%;
    height: var(--more-order-details-container-height);
    position: relative;
}

.mobile-orders-table-row-view-more-details,
.orders-table-row-view-more-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px 0;
    background-color: #fafafa;
}

.orders-table-row-view-more-details {
    gap: 15px;
}

.mobile-orders-table-row-view-more-details {
    gap: 10px;
}