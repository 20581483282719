.add-influencer-option {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
}

.add-influencer-option-image {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 1px solid #cccccc;
}

.MuiSelect-select {
    width: 100% !important;
    padding: 0 !important
}

.add-influencer-option-username {
    font-weight: 500;
    color: hsl(231, 17%, 39%);
}