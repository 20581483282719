.already-have-account-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.already-have-account-login-button {
    cursor: pointer;
    text-decoration: underline;
    color: var(--login-signup-button-background-color);
}