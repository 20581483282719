.mobile-paydin-drawer-list-content-option {
    text-align: start;
    padding: 15px;
    cursor: pointer;
    position: relative;
    text-transform: capitalize;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
}

.mobile-paydin-drawer-list-content-option:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.mobile-paydin-drawer-list-content-option::after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: #cccccc;
    width: 100%;
    content: "";
}

.mobile-paydin-drawer-list-content-option:active {
    background-color: hsl(0, 0%, 90%);
}

.mobile-paydin-drawer-list-content-option.disabled {
    color: hsl(69, 7%, 80%);
    cursor: none;
    pointer-events: none;
}