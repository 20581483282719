.analytics-data-card-title {
    color: #9599ae;
    font-size: 12px;
}

.analytics-data-card-value {
    font-size: 30px;
    font-weight: 300;
}

.analytics-data-card-image {
    width: 15px;
    height: 15px;
    color: #9599ae;
    margin-bottom: 5px;
}

.analytics-data-card-title-container {
    display: flex;
    align-items: center;
    gap: 10px;
}