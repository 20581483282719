.shorts-page-topbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0 20px 0;
    background-color: white;
    position: relative;
}

.mobile-shorts-page-topbar-search-box-container {
    padding: 10px 0;
    background-color: white;
    width: 95%;
    margin: 0 auto;
    position: relative;
}

.shorts-page-topbar::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #cccccc;
    position: absolute;
    z-index: 90;
    right: 0;
    bottom: 0;
}

.shorts-page-topbar::after {
    width: 100%;
    left: 0;
}

.shorts-page-container,
.mobile-shorts-page-container {
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.shorts-page-container {
    height: 100dvh;
}

.mobile-shorts-page-container {
    height: calc(100dvh - var(--mobile-dashboard-topbar-height));
}

.shorts-page-container::-webkit-scrollbar {
    display: none;
}

.shorts-page-topbar-store-image,
.mobile-shorts-page-topbar-store-image {
    width: 150px;
    aspect-ratio: 5 / 2;
}

.shorts-page-topbar-store-name-container,
.mobile-shorts-page-topbar-store-name-container,
.shorts-page-topbar-buttons-container,
.mobile-shorts-page-topbar-buttons-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.shorts-page-topbar-store-name,
.mobile-shorts-page-topbar-store-name {
    font-weight: 500;
    font-size: 17px;
}

.shorts-page-topbar-button,
.mobile-shorts-page-topbar-button {
    background-color: white;
    border: 1.5px solid black;
    cursor: pointer;
    border-radius: 4px;
    height: 35px;
}

.shorts-page-topbar-button:active,
.mobile-shorts-page-topbar-button:active {
    background-color: hsl(0, 0%, 85%);
}

.shorts-page-topbar-create-new-link-button {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 13px;
    padding: 10px 20px;
}

.shorts-page-topbar-more-options-button,
.mobile-shorts-page-topbar-more-options-button {
    width: 35px;
    padding: 5px;
}

.shorts-page-content.no-shorts-content,
.mobile-shorts-page-content.mobile-no-shorts-content {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 500px;
}

.mobile-shorts-page-content.mobile-no-shorts-content {
    padding-top: 20dvh;
}

.shorts-page-content-shorts-list,
.mobile-shorts-page-content-shorts-list,
.mobile-shorts-page-content {
    display: grid;
    gap: 15px;
}

.mobile-shorts-page-content-shorts-list {
    height: 100%;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    overflow: auto;
    gap: 5px;
}

.shorts-page-content {
    position: relative;
    overflow: auto;
    min-height: 500px;
    padding-top: 25px;
    padding-bottom: 20dvh;
}

.shorts-page-content-shorts-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    height: 100%;
    overflow: auto;
    padding: 5px 5px 15px 5px;
}

.mobile-shorts-page-content {
    margin-top: 0;
    padding: 25px 10px var(--page-bottom-padding) 10px;
    overflow: hidden;
}

.mobile-shorts-page-topbar-search-box,
.shorts-page-topbar-search-box {
    padding: 5px 10px;
    border: 1px solid hsl(225, 18%, 90%);
    display: flex;
    align-items: center;
    gap: 10px;
    height: 35px;
    margin: auto 0;
    border-radius: 3px;
}

.shorts-page-topbar-search-box-input {
    border: none;
    outline: none;
    width: 100%;
}

.shorts-page-topbar-search-box-input::placeholder {
    font-size: 15px;
    font-weight: 500;
    color: #b8bcd2;
}

.shorts-page-topbar-search-box-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.shorts-page-topbar-search-box-image.close {
    cursor: pointer;
}

.shorts-page-no-results-found-container {
    top: calc(var(--dashboard-topbar-height) + 10px);
}

.shorts-page-no-results-found-container,
.mobile-shorts-page-no-results-found-container {
    position: absolute;
    left: 0;
    width: 100%;
    height: 30dvh;
    max-height: 350px;
    background-color: white;
    z-index: 50;
}

.mobile-shorts-page-no-results-found-container {
    top: calc(var(--mobile-dashboard-topbar-height) + 35px + 20px);
}

.shorts-page-no-results-found-content {
    position: absolute;
    inset: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
}

.shorts-page-no-results-found-text {
    font-size: 20px;
    color: #B0B0B0;
    text-align: center;
}

.shorts-page-no-shorts-image {
    width: 50px;
    height: 50px;
}

.shorts-page-loader-row,
.mobile-shorts-page-loader-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.shorts-page-loader-row {
    height: 90px;
    margin-bottom: 30px;
}

.mobile-shorts-page-loader-row {
    height: 50px;
    margin-bottom: 15px;
}