.mobile-utm-form-input-container,
.utm-form-input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
}

.mobile-utm-form-input-container {
    width: 100%;
}

.utm-form-input-title {
    font-size: 14px;
    font-weight: 600;
}

.mobile-utm-form-input,
.utm-form-input {
    outline: none;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 10px 12px;
    font-size: 14px;
}

.mobile-utm-form-input {
    width: 100%;
}

.utm-form-input::placeholder {
    font-size: 14px;
}

.utm-form-input-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.utm-form-input-add-parameter-button {
    font-size: 12px;
    color: #1873e2;
    cursor: pointer;
}

.utm-form-input-utm-param-additions-menu {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 0 5px 3px #cccccc;
    position: absolute;
    top: 20px;
    right: 0;
    z-index: 80;
}

.mobile-utm-form-input-utm-param-addition,
.utm-form-input-utm-param-addition {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 6px;
    cursor: pointer;
}

.utm-form-input-utm-param-addition {
    width: 245px;
}

.utm-form-input-utm-param-addition:hover {
    background-color: hsl(0, 0%, 89%);
}

.utm-form-input-utm-param-addition:active {
    background-color: hsl(0, 0%, 80%);
}

.utm-form-input-utm-param-addition-title {
    font-size: 16px;
}

.mobile-utm-form-input-utm-param-addition-title {
    font-size: 16px;
}

.utm-form-input-utm-param-addition-description {
    font-size: 12px;
    color: gray;
}

.mobile-utm-form-input-utm-param-addition-description {
    font-size: 11px;
}

.utm-form-input-add-parameter-button-container {
    position: relative;
}