.mobile-paydin-chart-content,
.paydin-chart-container,
.mobile-paydin-chart-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.paydin-chart-content {
    padding: 20px;
}

.mobile-paydin-chart-content {
    padding: 20px 5px;
}

.mobile-paydin-chart-container {
    gap: 20px;
}

.paydin-chart-title {
    font-size: 22px;
}

.mobile-paydin-chart-displayed-graphs-labels,
.paydin-chart-displayed-graphs-labels {
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.paydin-chart-displayed-graphs-labels {
    justify-content: space-evenly;
    gap: 10px;
}

.mobile-paydin-chart-displayed-graphs-labels {
    flex-wrap: wrap;
    justify-content: space-around;
}

.mobile-paydin-chart,
.paydin-chart {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 20px 0 35px 0;
    border-radius: 15px;
    box-shadow: 0 0 5px 3px #cccccc;
    position: relative;
    background-color: white;
}

.paydin-chart {
    aspect-ratio: 2.5;
}

.mobile-paydin-chart {
    min-height: 320px;
    gap: 25px;
}

.paydin-chart-no-data-frame {
    width: 100%;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffffdd;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 70;
}

.paydin-chart-no-data-message,
.mobile-paydin-chart-no-data-message {
    font-weight: 500;
}

.paydin-chart-no-data-message {
    font-size: 21px;
}

.mobile-paydin-chart-no-data-message {
    font-size: 14px;
}