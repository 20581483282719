.MuiDialog-paper {
    padding: 0 !important;
}

.free-mode-dialog-content,
.mobile-free-mode-dialog-content {
    display: flex;
    width: 100%;
    height: 100%;
}

.mobile-free-mode-dialog-content {
    flex-direction: column-reverse;
}

.free-mode-dialog-content-left-section,
.mobile-free-mode-dialog-content-left-section {
    width: 55%;
    padding: 35px 35px 25px 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.mobile-free-mode-dialog-content-left-section {
    width: 100%;
    height: 60%;
    padding: 20px 20px;
}

.free-mode-dialog-content-left-section-top-section,
.mobile-free-mode-dialog-content-left-section-top-section {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.mobile-free-mode-dialog-content-left-section-top-section {
    gap: 20px;
}

.free-mode-dialog-content-right-section,
.mobile-free-mode-dialog-content-right-section {
    width: 45%;
    border-radius: 12px;
    background-color: #eeeeee;
    padding: 3% 0% 3% 3%;
}

.mobile-free-mode-dialog-content-right-section {
    width: 100%;
    height: 40%;
    border-radius: 0;
}

.free-mode-dialog-content-image {
    width: 100%;
    height: 100%;
}

.free-mode-dialog-content-left-section-title {
    font-size: 32px;
}

.mobile-free-mode-dialog-content-left-section-title {
    font-size: 17px;
}

.free-mode-dialog-content-left-section-subtitle {
    font-size: 17px;
    white-space: pre;
}

.mobile-free-mode-dialog-content-left-section-subtitle {
    font-size: 15px;
    white-space: pre-wrap;
}

.free-mode-dialog-content-options-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.free-mode-dialog-content-option {
    display: flex;
    align-items: start;
    gap: 15px;
}

.free-mode-dialog-content-option-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.free-mode-dialog-content-option-text {
    font-size: 16px;
}

.mobile-free-mode-dialog-content-option-text {
    font-size: 13px;
}

.free-mode-dialog-content-option-4-learn-more-text {
    text-decoration: underline;
    color: #4472c5;
    display: inline;
    cursor: pointer;
}

.free-mode-dialog-content-cancel-anytime-text {
    font-size: 15px;
    font-weight: 500;
}

.free-mode-dialog-content-upgrade-button,
.mobile-free-mode-dialog-content-upgrade-button {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #4472c5;
    padding: 10px 25px;
    cursor: pointer;
    width: fit-content;
    border-radius: 10px;
}

.mobile-free-mode-dialog-content-upgrade-button {
    padding: 5px 15px;
    width: 100%;
    justify-content: center;
}

.free-mode-dialog-content-upgrade-button:active {
    filter: brightness(90%);
}

.free-mode-dialog-content-upgrade-button-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    color: white;
    fill: white;
}

.free-mode-dialog-content-upgrade-button-text {
    font-size: 20px;
    color: white;
}

.mobile-free-mode-dialog-content-upgrade-button-text {
    font-size: 16px;
    color: white;
}