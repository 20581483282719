.mobile-link-order-product,
.link-order-product {
    --link-order-product-padding-inline: 40px;
    --link-order-product-image-ratio: 1.3;
    --link-order-product-height: 80px;
    --mobile-link-order-product-height: 70px;
    --link-order-product-image-titles-container-width: calc((100% - (var(--link-order-product-padding-inline) * 2)) * 0.5);
    --mobile-link-order-product-image-titles-container-width: calc((100% - (var(--link-order-product-padding-inline) * 2)) * 0.7);

    width: 100%;
    display: flex;
    align-items: center;
}
.link-order-product {
    padding-inline: var(--link-order-product-padding-inline);
    gap: 15px;
}

.mobile-link-order-product {
    padding-inline: 10px;
}

.link-order-product-image {
    height: var(--link-order-product-height);
    aspect-ratio: 1 / var(--link-order-product-image-ratio);
}

.mobile-link-order-product-image {
    height: var(--mobile-link-order-product-height);
    aspect-ratio: 1 / var(--link-order-product-image-ratio);
}

.link-order-product-title {
    font-size: 13px;
    width: calc(100% - (var(--link-order-product-height) * (var(--link-order-product-image-ratio) - 1)) - 20px);
}

.mobile-link-order-product-title {
    font-size: 12px;
    width: 12ch;
}

.link-order-product-variant-title-container {
    width: calc((100% - (var(--link-order-product-padding-inline) * 2)) * 0.3);
}

.mobile-link-order-product-variant-title-container {
    width: calc((100% - (var(--mobile-link-order-product-padding-inline) * 2)) * 0.3);
}

.mobile-link-order-product-variant-title,
.link-order-product-variant-title {
    background-color: #c6c6c6;
    border-radius: 5px;
}

.link-order-product-variant-title {
    padding: 3px 10px;
    max-width: 100%;
    width: fit-content;
    font-size: 13px;
}

.mobile-link-order-product-variant-title {
    max-width: 15ch;
    font-size: 11px;
    padding: 5px 10px;
}

.link-order-product-price-details,
.link-order-product-total-price {
    font-size: 15px;
    flex-grow: 1;
    text-align: end;
}

.mobile-link-order-product-price-details,
.mobile-link-order-product-total-price {
    font-size: 12px;
    flex-grow: 1;
    text-align: end;
}

.mobile-link-order-product-titles-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}

.link-order-product-image-titles-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: var(--link-order-product-image-titles-container-width);
}

.mobile-link-order-product-image-titles-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: var(--mobile-link-order-product-image-titles-container-width);
}