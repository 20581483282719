.mobile-page-view-topbar,
.page-view-topbar {
    padding: 0 15px;
    width: 100%;
    height: var(--content-dialog-topbar-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    position: fixed;
    z-index: 300;
    background-color: white;
    top: 0;
    left: 0;
}

.mobile-page-view-topbar {
    padding: 0;
}

.page-view-topbar::after,
.mobile-page-view-topbar::after {
    content: "";
    display: block;
    position: absolute;
    background-color: #cccccc;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 50;
}

.page-view-title {
    text-align: center;
    font-weight: 500;
    width: 100%;
}