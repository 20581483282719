.dashboard-data-multi-value-values-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.dashboard-data-multi-value-data-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2px;
}

.dashboard-data-multi-value-data-value {
    font-size: 25px;
    font-weight: 300;
}

.dashboard-data-multi-value-data-value-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.dashboard-data-multi-value-data-value-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    flex-shrink: 0;
}

.dashboard-data-multi-value-data-value-text {
    font-size: 14px;
    font-weight: 300;
}