.sign-up-page-container {
    height: 100vh;
}

.sign-up-page-container.register-success {
    height: 100%;
}

.sign-up-page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    width: 100%;
}

.sign-up-page-fields-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.input-field {
    border: none;
    font-size: 15px;
    background-color: transparent;
    height: 100%;
    border-radius: inherit;
    width: 100%;
    outline: none;
    flex: 1 0;
}

.input-field-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: #e2e2e2;
    border-radius: 7px;
    padding: 0 10px;
}

.input-field-validation-indication {
    width: 20px;
    height: 20px;
    position: relative;
}

.input-field-valid-indicator,
.input-field-invalid-indicator {
    width: 100%;
    height: 100%;
}

.input-field-invalid-indicator {
    stroke: red;
}

.input-field-valid-indicator {
    fill: green;
}

.sign-up-page-sign-up-button:active {
    background-color: hsl(198, 93%, 53%);
}

.sign-up-page-sign-up-button {
    width: 100%;
    text-align: center;
    background-color: var(--login-signup-button-background-color);
    color: white;
    padding: 15px 0;
    border-radius: 30px;
    cursor: pointer;
    pointer-events: all;
}

.sign-up-page-sign-up-button.disabled {
    background-color: #cccccc7f;
    color: #cccccc;
    cursor: none;
    pointer-events: none;
}