.mobile-link-view-container,
.link-view-container {
    --link-view-main-frame-min-height: 590px;

    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.link-view-container {
    height: 100%;
}

.mobile-link-view-container {
    gap: 0;
    height: 100dvh;
}

.mobile-link-view-content,
.link-view-content {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.link-view-content {
    margin-top: var(--content-dialog-topbar-height);
    height: 100%;
    overflow: auto;
}

.mobile-link-view-content {
    padding-top: 20px;
    overflow-y: auto;
}

.mobile-link-view-details-and-simulator,
.link-view-details-and-simulator {
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.link-view-details-and-simulator  {
    min-height: var(--link-view-main-frame-min-height);
    position: relative;
    height: 100%;
}

.mobile-link-view-details-and-simulator {
    padding-top: 40px;
}

.mobile-link-view-topbar,
.link-view-topbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link-view-topbar {
    padding: 0;
    margin: 0;
}

.mobile-link-view-topbar {
    padding-inline-end: 10px;
    position: fixed;
    z-index: 50;
    background-color: white;
}

.link-view-topbar-buttons-container {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;
}

.link-view-topbar-back-button,
.link-view-topbar-button {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.link-view-topbar-button {
    width: fit-content;
    padding: 5px 10px;
    border: 1px solid hsl(228, 23%, 60%);
    border-radius: 4px;
    color: hsl(231, 9%, 22%);
}

.link-view-topbar-button:active {
    background-color: hsl(0, 0%, 90%);
}

.link-view-topbar-button-image {
    width: 20px;
    height: 20px;
}

.link-view-topbar-back-button-image {
    width: 25px;
    height: 25px;
}

.link-view-topbar-button-text {
    font-size: 14px;
    font-weight: 300;
}

.link-view-topbar-back-button {
    color: black;
    width: fit-content;
}

.link-view-topbar-back-button-text {
    font-weight: 300;
}

.link-view-topbar-center-title {
    text-align: center;
}

.mobile-link-view-details-container,
.link-view-details-container {
    min-height: 100%;
}

.link-view-details-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding-top: 20px;
    border-right: 1px solid #cccccc;
    overflow-y: auto;
    height: 100%;
}

.link-view-details-container::-webkit-scrollbar {
    display: none;
}

.link-view-details-content {
    min-height: var(--link-view-main-frame-min-height);
}

.mobile-link-view-details-container {
    width: 100%;
}

.link-view-preview-container {
    width: 30%;
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
}

.mobile-link-view-mobile-preview-close-button {
    width: 25px;
    height: 25px;
}

.link-view-options-menu-delete-option,
.mobile-link-item-more-options-menu-section-item-delete-link {
    color: red;
}

.link-view-more-options-button {
    display: block;
    width: 45px;
    height: 45px;
    padding: 11px;
    margin-left: auto;
    cursor: pointer;
}