.mobile-edit-link-section-container,
.edit-link-section-container {
    --image-container-dimens: 135px;
    --mobile-image-container-dimens: 120px;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
}

.mobile-edit-link-section-link-details,
.edit-link-section-link-details {
    display: flex;
    align-items: start;
    gap: 15px;
}

.edit-link-section-link-details {
    padding-bottom: 15px;
    padding-inline: 20px;
}

.mobile-edit-link-section-link-image-container,
.edit-link-section-link-image-container {
    aspect-ratio: 1;
    flex-shrink: 0;
    position: relative;
}

.edit-link-section-link-image-container {
    width: var(--image-container-dimens);
}

.mobile-edit-link-section-link-image-container {
    width: var(--mobile-image-container-dimens);
}

.edit-link-section-link-image-container:hover>.edit-link-section-link-image {
    opacity: 0.5;
}

.edit-link-section-link-image-container:hover>.edit-link-section-add-media-button {
    display: block;
}

.edit-link-section-add-media-button-plus,
.edit-link-section-add-media-button-text {
    color: var(--add-media-container-color);
}

.edit-link-section-add-media-button-plus {
    font-size: 35px;
    font-weight: 200;
}

.edit-link-section-link-image {
    width: 100%;
    height: 100%;
}

.edit-link-section-link-data-form-inputs {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 15px;
}

.edit-link-section-link-data-link-visibility {
    display: flex;
    align-items: start;
    gap: 10px;
}

.edit-link-section-link-data-link-visibility-title-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
}

.edit-link-section-link-data-link-visibility-title {
    font-size: 18px;
    color: hsl(233, 27%, 25%);
}

.edit-link-section-link-data-link-visibility-subtitle-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.edit-link-section-link-data-link-visibility-subtitle {
    font-size: 14px;
    color: hsl(227, 14%, 60%);
    font-weight: 300;
    white-space: pre-line;
}

.edit-link-section-link-data-link-visibility-upgrade-button {
    font-size: 14px;
    padding: 5px 10px;
    background-color: black;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    flex: 0 0;
}

.edit-link-section-link-data-link-visibility-upgrade-button:hover {
    background-color: hsl(0, 0%, 20%);
}

.edit-link-section-add-media-button,
.edit-link-section-add-media-button.has-image,
.mobile-edit-link-section-add-media-button,
.edit-link-section-add-media-button {
    display: block;
    aspect-ratio: 1;
    border: 1px solid var(--add-media-container-color);
    cursor: pointer;
}

.mobile-edit-link-section-add-media-button.has-image,
.edit-link-section-add-media-button.has-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    display: none;
}

.edit-link-section-add-media-button-inner-container {
    width: 95%;
    height: 95%;
    border: 1px dotted var(--add-media-container-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2.5%;
    color: var(--add-media-container-color);
}

.edit-link-section-add-media-button-plus {
    font-size: 35px;
    font-weight: 200;
}

.mobile-edit-link-section-add-media-button-plus {
    font-size: 25px;
    font-weight: 200;
}

.edit-influencer-section-image-container:hover>.edit-influencer-section-image {
    opacity: 0.5;
}

.edit-influencer-section-image-container:hover>.edit-link-section-add-media-button {
    display: block;
}

.edit-link-section-button,
.mobile-edit-link-section-button {
    width: 50%;
    align-self: end;
    color: white;
    background-color: black;
    text-transform: uppercase;
    font-size: 17px;
    text-align: center;
    padding: 12px 0;
    cursor: pointer;
    pointer-events: all;
    margin-top: 30px;
}

.edit-link-section-button.disabled,
.mobile-edit-link-section-button.disabled {
    opacity: 0.5;
    cursor: pointer;
    pointer-events: none;
}

.mobile-edit-link-section-button {
    width: 100%;
    margin-bottom: 10px;
}

.line {
    width: 100vw;
    height: 1px;
    background-color: #cccccc;
    position: relative;
    left: -20px;
}

.mobile-edit-link-section-link-title-container,
.edit-link-section-link-title-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.mobile-edit-link-section-link-title-container {
    gap: 5px;
    padding-inline: 20px;
    padding-top: 15px;
}

.edit-link-section-link-title-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.edit-link-section-link-title-header-image {
    width: 30px;
    aspect-ratio: 1;
    flex-shrink: 0;
}

.edit-link-section-link-title {
    font-size: 14px;
    font-weight: 500;
    color: black;
    width: 100%;
}

.edit-link-section-link-fields {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding-bottom: 20dvh;
}

.edit-link-section-add-products-button {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
}

.mobile-edit-link-section-top-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #cccccc;
}

.mobile-edit-link-section-image-section {
    display: flex;
    align-items: start;
}

.mobile-edit-link-section-image-section-data {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    width: 100%;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 15px;
}

.mobile-edit-link-section-promocode-input-container,
.edit-link-section-promocode-input-container {
    padding-bottom: 15px;
    border-bottom: 1px solid #cccccc;
    width: 100%;
    padding: 15px 20px;
}

.mobile-edit-link-section-link-products-slider {
    width: 100vw !important;
    padding-inline: 20px !important;
}

.mobile-edit-link-section-add-products-button-container,
.edit-link-section-add-products-button-container {
    width: 100%;
    border-bottom: 1px solid #cccccc;
}

.mobile-edit-link-section-add-products-button-padding-container,
.edit-link-section-add-products-button-padding-container {
    width: 100%;
    padding-inline: 20px;
}

.mobile-edit-link-section-utm-container,
.edit-link-section-utm-container {
    width: 100%;
    padding-top: 15px;
}