.forgot-password-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.forgot-password-input-field {
    border: none;
    font-size: 15px;
    width: 100%;
    outline: none;
    height: 50px;
    background-color: #e2e2e2;
    border-radius: 7px;
    padding: 0 10px;
}

.forgot-password-page-title-section,
.forgot-password-page-input-button-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    text-align: center;
}

.forgot-password-reset-password-button {
    padding: 15px 0;
    margin: 10px;
    border-radius: 30px;
    background-color: var(--login-signup-button-background-color);
    cursor: pointer;
    pointer-events: all;
    color: white;
}

.forgot-password-reset-password-button:active {
    background-color: hsl(198, 93%, 38%);
}

.forgot-password-reset-password-button.disabled {
    cursor: none;
    pointer-events: none;
    background-color: #a6a5a5;
}

.forgot-password-page-back-to-login-button {
    text-align: center;
    text-decoration: underline;
    font-size: 15px;
    color: var(--login-signup-button-background-color);
    cursor: pointer;
}