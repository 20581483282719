.shopify-connect-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80%;
}

.shopify-connect-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 30px 60px;
    background-color: hsl(225, 29%, 94%);
    border-radius: 10px;
}

.shopify-connect-connection-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
    padding: 10px;
}

.shopify-connect-upper-frame {
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.shopify-connect-connection-frame-shopify-logo,
.shopify-connect-connection-frame-paylink-small-logo {
    width: 40px;
    height: 40px;
    box-shadow: 0 0 5px 3px #cccccc;
    padding: 5px;
    border-radius: 10px;
}

.shopify-connect-connection-frame-arrow-separator {
    width: 20px;
    height: 20px;
}

.shopify-connect-connection-frame-image {
    padding: 5px;
}

.shopify-connect-title,
.shopify-connect-you-are-logged-in-as-title,
.shopify-connect-agree-to-share-information-title,
.shopify-connect-launch-title-section {
    text-align: center;
}

.shopify-connect-title {
    font-weight: 600;
}

.shopify-connect-you-are-logged-in-as-title,
.shopify-connect-agree-to-share-information-title,
.shopify-connect-you-can-disconnect-text {
    font-size: 14px;
}

.shopify-connect-buttons-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.shopify-connect-button {
    padding: 10px 0;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
}

.shopify-connect-connect-to-different-account-button {
    background-color: black;
    color: white;
}

.shopify-connect-connect-to-different-account-button:active {
    background-color: hsl(0, 0%, 20%)
}

.shopify-connect-connect-shopify-account-button,
.shopify-connect-connect-shopify-store-button {
    background-color: hsl(147, 99%, 35%);
    color: white;
}

.shopify-connect-connect-shopify-account-button:active,
.shopify-connect-connect-shopify-store-button:active {
    background-color: hsl(147, 99%, 25%);
}

.shopify-connect-connect-shopify-store-button {
    font-size: 13px;
    border: none;
}

.shopify-connect-connect-shopify-store-button.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: none;
}

.shopify-connect-cancel-button {
    background-color: white;
    color: black;
    border: 1px solid hsl(60, 8%, 80%);
}

.shopify-connect-cancel-button:active {
    background-color: rgb(245, 245, 245);
}

.shopify-connect-launch-title-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: start;
}

.shopify-connect-launch-store-title {
    font-weight: 500;
    font-size: 25px;
}

.shopify-connect-launch-store-subtitle {
    font-weight: 300;
    font-size: 15px;
}

.shopify-connect-store-provider-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-self: start;
}

.shopify-connect-store-provider {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.shopify-connect-store-provider-name {
    font-size: 20px;
    font-weight: 500;
}

.shopify-connect-store-provider-title {
    font-size: 19px;
    font-weight: 600;
    text-align: start;
    width: 100%;
    margin-top: 20px;
}

.shopify-connect-store-provider-image-container {
    width: 70px;
    height: 70px;
    border: 1.5px solid #f0f1ed;
    border-radius: 9px;
}

.shopify-connect-store-provider-image {
    padding: 5px;
}

.shopify-connect-shop-url-input-container {
    width: 100%;
    height: 50px;
}

.shopify-connect-shop-url-input-content {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 100%;
    border-radius: 10px;
    padding: 0 20px;
    background-color: #f2f2f2;
}

.shopify-connect-shop-url-input-suffix {
    color: #5d6271;
}

.shopify-connect-shop-url-input {
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    background-color: transparent;
}

.login-page-login-error-message {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    width: 95%;
    background-color: #ffdce0;
    color: #d74454;
    border: 1px solid #feb1bb;
}

.login-page-login-error-message-text {
    text-align: start;
}