.no-items-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.no-items-found-container-image {
    width: 65px;
    height: 65px;
}

.no-items-found-titles-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
}

.no-items-found-titles-container-title {
    font-size: 25px;
    font-weight: 300;
}

.mobile-no-items-found-titles-container-title {
    font-size: 20px;
    font-weight: 300;
}

.no-items-found-titles-container-subtitle {
    font-size: 15px;
    font-weight: 300;
    white-space: pre-line;
}

.no-items-found-add-item-button {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 13px;
    padding: 10px 20px;
    background-color: white;
    border: 1.5px solid black;
    cursor: pointer;
    border-radius: 4px;
    height: 35px;
    margin-top: 20px;
}

.no-items-found-add-item-button:active {
    background-color: hsl(0, 0%, 85%);
}