.mobile-edit-multilink-section-container,
.edit-multilink-section-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0;
}

.edit-multilink-section-container {
    padding-top: 20px;
}

.mobile-edit-multilink-section-title-container,
.edit-multilink-section-title-container {
    padding: 15px 20px;
    border-bottom: 1px solid #cccccc;
    width: 100%;
}

.edit-multilink-section-url-input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.edit-multilink-section-url-input {
    width: 100%;
    outline: none;
    border: 1px solid black;
    border-radius: 25px;
    padding: 10px 15px;
}

.edit-multilink-section-url-input::placeholder {
    color: #7f859e;
}

.edit-multilink-section-button,
.mobile-edit-multilink-section-button {
    align-self: end;
    color: white;
    background-color: black;
    text-transform: uppercase;
    font-size: 17px;
    text-align: center;
    padding: 12px 0;
    cursor: pointer;
    pointer-events: all;
    margin-top: 30px;
}

.edit-multilink-section-button {
    width: 50%;
}

.mobile-edit-multilink-section-button {
    width: 100%;
}

.edit-multilink-section-button.disabled,
.mobile-edit-multilink-section-button.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: none;
}

.edit-multilink-section-website-iframe {
    width: 100%;
    height: 100%;
}

.edit-multilink-section-url-preview-container {
    width: 100%;
    height: 25dvh;
    position: relative;
}

.edit-multilink-section-url-selection-types {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding-inline: 20px;
}

.edit-multilink-section-url-selection-type-image {
    color: #a7a7a7;
    width: 50px;
    height: 50px;
}


.edit-multilink-section-url-selection-svg-type-image path {
    fill: #a7a7a7;
}

.mobile-edit-multilink-section-title-container,
.edit-multilink-section-title-container,
.mobile-edit-multilink-section-url-container,
.edit-multilink-section-url-container,
.mobile-edit-multilink-section-promocode-container,
.edit-multilink-section-promocode-container {
    padding: 15px 20px;
    border-bottom: 1px solid #cccccc;
    width: 100%;
}

.mobile-edit-multilink-section-url-container.second,
.edit-multilink-section-url-container.second {
    border-bottom: none;
}

.mobile-edit-multilink-section-influencer-select-container,
.edit-multilink-section-influencer-select-container {
    border-bottom: 1px solid #cccccc;
    width: 100%;
}

.mobile-edit-multilink-section-utm-container,
.edit-multilink-section-utm-container {
    padding: 15px 0;
    width: 100%;
}

.edit-multilink-section-utm-container {
    padding: 15px 0;
}

.edit-multilink-section-alert-message-container {
    padding: 0 30px 20px 30px;
    width: 100%;
}

.edit-multilink-sublink-sections-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #cccccc;
    gap: 10px;
}

.edit-multilink-sublink-section {
    width: 100%;
    margin: 15px 0;
}

.edit-multilink-section-header-container {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    padding-inline: 20px;
    margin: 0 0 5px 0;
}

.edit-multilink-section-header,
.edit-multilink-section-subheader {
    text-align: start;
    font-weight: 500;
}

.edit-multilink-section-header {
    font-size: 16px;
    color: black;
}

.edit-multilink-section-subheader {
    font-size: 14px;
    color: #a2a4b5;
    font-weight: 300;
}

.mobile-edit-multilink-section-multilink-title-container,
.edit-multilink-section-multilink-title-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding: 0 20px 20px 20px;
    margin: 15px 0;
    border-bottom: 1px solid #cccccc;
}

.mobile-edit-multilink-section-multilink-title-container {
    gap: 5px;
    padding-inline: 20px;
    padding-top: 15px;
}

.edit-multilink-section-multilink-title-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.edit-multilink-section-multilink-title-header-image {
    width: 30px;
    aspect-ratio: 1;
    flex-shrink: 0;
}

.edit-multilink-section-experience-header-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    padding-inline: 20px;
    margin: 0 0 15px 0 ;
}

.edit-multilink-section-experience-header {
    text-align: start;
    font-weight: 500;
    font-size: 16px;
    color: black;
    flex-grow: 2;
}

.mobile-edit-multilink-section-experience-options,
.edit-multilink-section-experience-options {
    display: flex;
}

.mobile-edit-multilink-section-experience-options {
    flex-direction: column;
    gap: 5px
}

.edit-multilink-section-experience-options {
    flex-direction: row;
    gap: 30px;
}



.edit-multilink-section-device-select {
    height: 35px;
    align-items: center;
    flex-direction: row;
    display: flex;
    width: 205px;
    gap: 10px;
}

.edit-multilink-section-experience-device-option-header,
.edit-multilink-section-experience-search-box-header {
    text-align: start;
    font-weight: 500;
    font-size: 14px;
    color: grey;
    width: 50px;
}

.edit-multilink-section-experience-search-container {
    width: 150px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}


.edit-multilink-section-experience-device-option-box, 
.edit-multilink-section-search-box {
    border: 1px solid hsl(225, 18%, 90%);
    display: flex;
    align-items: center;
    gap: 10px;
    height: 35px;
    margin: auto 0;
    border-radius: 5px;
}
.edit-multilink-section-search-box {
    padding: 5px 10px;
    width: 100px;
}

.edit-multilink-section-experience-device-option-box {
    padding: 5px 0 5px 10px;
    width: 140px;
}

.edit-multilink-section-search-box-input {
    border: none;
    outline: none;
    width: 100%;
}

.edit-multilink-section-search-box-input::placeholder {
    font-size: 15px;
    font-weight: 500;
    color: #555555;
}

.edit-multilink-section-search-box-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.edit-multilink-section-search-box-image.close {
    cursor: pointer;
}

.edit-multilink-section-add-products-button-padding-container {
    width: 100%;
    padding: 20px;
}


.edit-multilink-section-add-products-button-padding-container.second {
    padding-bottom: 0;
}

.edit-multilink-section-add-products-button-padding-container.first {
    border-bottom: 1px solid #cccccc;
}

.edit-multilink-section-add-products-button {
    padding: 0;
}