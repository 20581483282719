.home-page-section {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-top: 20px;
}

.home-page-section-titles-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 7px;
}

.home-page-section-title {
    font-size: 22px;
    font-weight: 600;
}

.home-page-section-description {
    font-size: 14px;
}