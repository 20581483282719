.items-view-container-slider {
    display: flex;
    align-items: center;
    overflow-x: auto;
    white-space: nowrap;    
    width: 100%;
}

/* .items-view-container-slider::-webkit-scrollbar {
    height: 5px;
} */

/* .items-view-container-slider::-webkit-scrollbar-track-piece {
    background: #888
} */
/* .items-view-container-slider::-webkit-scrollbar-thumb {
    background: #eee
} */
/* .items-view-container-slider::-webkit-scrollbar-button {
    border-radius: 10px;
} */

.items-view-container-grid {
    width: 100%;
    display: grid;
    justify-items: center;
    grid-column-gap: 10px;
    grid-row-gap: 15px;
}