.login-store-page-container {
    height: 100vh;
}

.login-page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    width: 100%;
}

.login-page-fields-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.login-page-forgot-password-button {
    width: fit-content;
    font-size: 14px;
}

.login-page-login-button {
    width: 100%;
    text-align: center;
    background-color: var(--login-signup-button-background-color);
    color: white;
    padding: 15px 0;
    border-radius: 30px;
    cursor: pointer;
    pointer-events: all;
}

.login-page-login-button:active {
    background-color: hsl(198, 93%, 53%);
}

.login-page-login-button.disabled {
    background-color: #cccccc7f;
    color: #cccccc;
    cursor: none;
    pointer-events: none;
}

.login-page-dont-have-account-section {
    font-size: 15px;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 5px;
}

.login-page-dont-have-account-sign-up-button {
    cursor: pointer;
    text-decoration: underline;
    color: var(--login-signup-button-background-color);
}