.password-input-rule-container {
    display: flex;
    align-items: center;
    gap: 7px;
    color: red;
}

.password-input-rule-image {
    width: 20px;
    height: 20px;
}

.password-input-rule-container.fulfilled {
    color: green;
}

.password-input-rule-validation-image-fulfilled {
    color: green;
}

.password-input-rule-validation-image > path {
    stroke: red;
}

.password-input-rule-text {
    font-size: 14px;
    color: var(--password-instructions-text-color);
}