.mobile-date-interval-option,
.date-interval-option {
    width: 100%;
    font-weight: 500;
    color: black;
    border-radius: 8px;
    cursor: pointer;
}

.date-interval-option {
    padding: 5px 10px;
    font-size: 12px;
}

.mobile-date-interval-option {
    font-size: 14px;
    background-color: #efefef;
    padding: 7px 12px;
}

.mobile-date-interval-option:first-child {
    margin-inline-start: 5px;
}

.mobile-date-interval-option:last-child {
    margin-inline-end: 5px;
}

.mobile-date-interval-option.selected {
    background-color: #e0f1fe;
    color: #45acf7;
}

.date-interval-option:hover {
    background-color: hsl(218, 52%, 88%);
    color: white;
}

.date-interval-option.selected {
    color: white;
    background-color: #216ba5;
}