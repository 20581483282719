.mobile-todo-list-container,
.todo-list-container {
    position: relative;
}

.mobile-todo-list-container::after {
    content: "";
    display: block;
    height: 13px;
    width: calc(100% + 40px);
    background-color: #e7ede9;
    position: absolute;
    left: -20px;
    bottom: -20px;
    z-index: 90;
}

.todo-list-task-header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
}

.todo-list-task-header-checked-mark,
.todo-list-task-header-unchecked-mark {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
}

.todo-list-task-header-unchecked-mark {
    border-radius: 50%;
    border: 2px dotted #686666;
}

.todo-list-task-header-title {
    font-weight: 500;
    font-size: 15px;
}

.todo-list-task-content {
    padding-left: 32px;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.todo-list-task-details {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 30px;
    padding-inline-end: 30px;
}

.todo-list-task-content-description {
    font-size: 13px;
}

.todo-list-task-content-button {
    padding: 10px;
    border-radius: 10px;
    background-color: #3f3f3f;
    color: white;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
}

.todo-list-task-content-button:active {
    background-color: hsl(0, 0%, 35%);
}