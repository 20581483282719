.pricing-plan-container {
    display: inline-flex;
    flex-direction: column;
    gap: 15px;
    min-width: 265px;
    min-height: 550px;
    border-radius: 10px;
    border: 1px solid hsl(0, 0%, 80%);
}

.pricing-plan-color-bar {
    height: 20px;
    width: 100%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.pricing-plan-content {
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: start;
    position: relative;
    height: 100%;
    flex-grow: 1;
}

.pricing-plan-name {
    text-transform: capitalize;
    font-weight: 800;
}

.pricing-plan-pricing-method {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 20px;
}

.pricing-plan-price {
    font-size: 30px;
}

.pricing-plan-separator {
    width: 95%;
    height: 2px;
    background-color: #cccccc;
}

.pricing-plan-active-plan-label,
.pricing-plan-unavailable-plan-label,
.pricing-plan-contact-us-plan-label {
    padding: 10px;
    width: fit-content;
    margin: auto;
    color: white;
}

.pricing-plan-active-plan-label {
    background-color: black;
}

.pricing-plan-unavailable-plan-label {
    background-color: #cccccc;
}

.pricing-plan-contact-us-plan-label {
    background-color: green;
    padding: 10px 15px;
    display: block;
}

.pricing-plan-contact-us-plan-label:hover {
    color: white;
}

.pricing-plan-bottom-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    bottom: 20px;
    left: 0;
    text-align: center;
    width: 100%;
}

.pricing-plan-bottom-button {
    padding: 10px 15px;
    background-color: green;
    color: white;
    width: fit-content;
    margin: auto;
    cursor: pointer;
}

.pricing-plan-feature-row {
    font-size: 15px;
}

.pricing-plan-feature-v {
    width: 20px;
    height: 20px;
}

.pricing-plan-feature {
    word-wrap: break-word;
    margin-inline-start: 10px;
    display: inline;
}

.pricing-plan-description {
    white-space: pre-line;
    font-size: 13px;
    line-height: 1.5;
    color: #abaaaa;
    text-align: center;
}

.pricing-plan-subtitle {
    font-size: 30px;
}