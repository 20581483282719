.magic-store-page {
    position: relative;
}

.magic-store-gradient-background {
    background: linear-gradient(to bottom, var(--magic-purple-color), #84BFDE, #E5F6F7, white);
    width: 100%;
    height: 50dvh;
    position: absolute;
    top: 0;
    z-index: 300;
}

.mobile-magic-store-page-content,
.magic-store-page-content {
    padding-top: 6dvh;
    padding-inline: 65px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12dvh;
    position: absolute;
    top: 0;
    z-index: 400;
}

.mobile-magic-store-page-content {
    gap: 20px;
    padding-top: calc(var(--content-dialog-topbar-height) + 10px);
    padding-bottom: 20dvh;
    padding-inline: 30px;
}

.mobile-magic-store-page-title,
.magic-store-page-title {
    width: 100%;
    text-align: center;
    font-size: 65px;
    font-weight: 300;
    color: white;
}

.mobile-magic-store-page-title {
    font-size: 35px;
}

.mobile-magic-store-page-body,
.magic-store-page-body {
    width: 100%;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.mobile-magic-store-page-body {
    flex-direction: column-reverse;
}

.mobile-magic-store-page-left-section,
.magic-store-page-left-section {
    display: flex;
    flex-direction: column;
    gap: 65px;
}

.mobile-magic-store-page-left-section {
    gap: 30px;
}

.mobile-magic-store-page-left-section-title,
.magic-store-page-left-section-title {
    font-size: 35px;
    color: black;
}

.mobile-magic-store-page-left-section-title {
    font-size: 20px;
}

.magic-store-page-left-section-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.magic-store-page-right-section,
.magic-store-page-left-section {
    width: 50%;
}

.mobile-magic-store-page-right-section,
.mobile-magic-store-page-left-section {
    width: 100%;
}

.magic-store-page-right-section {
    height: 400px;
}

.mobile-magic-store-page-right-section {
    height: 180px;
}

.magic-store-page-left-section-content-option {
    display: flex;
    gap: 10px;
    align-items: start;
}

.magic-store-page-left-section-content-option-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.mobile-magic-store-page-left-section-content-option-text,
.magic-store-page-left-section-content-option-text {
    font-size: 14px;
    color: black;
}

.mobile-magic-store-page-left-section-content-option-text {
    font-size: 13px;
}

.mobile-magic-store-page-create-button,
.magic-store-page-create-button {
    background-color: var(--magic-purple-color);
    color: white;
    font-size: 16px;
    border-radius: 5px;
    padding: 10px 45px;
    width: fit-content;
    cursor: pointer;
}

.magic-store-page-create-button:active,
.mobile-magic-store-page-create-button:active {
    background-color: hsl(262, 91%, 71%);
}

.mobile-magic-store-page-create-button {
    align-self: center;
}

.magic-store-video-frame {
    width: 100%;
    height: 100%;
}