.trends-page-container,
.mobile-trends-page-container {
    width: 100%;
}

.mobile-trends-page-container {
    padding-top: 40px;
}

.trends-page-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0 20px 0;
    background-color: white;
    position: relative;
}

.trends-page-topbar::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #cccccc;
    width: 100%;
    position: absolute;
    z-index: 90;
    right: 0;
    left: 0;
    bottom: 0;
}

.trends-page-topbar-title {
    font-size: 28px;
    font-weight: 600;
}

.trends-page-section {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-top: 40px;
    margin-bottom: 60px;
}

.trends-page-section-titles-container {
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 50px;
    margin-inline: 20px;
}

.trends-page-section-titles-div {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 7px;
}

.trends-page-section-title {
    font-size: 22px;
    font-weight: 600;
}

.trends-page-section-description {
    font-size: 14px;
}

.top-performing-products-loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.top-performing-products-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    margin-inline: 20px;
}

.mobile-top-performing-products-container {
    width: 100%;
}

.mobile-top-performing-products-strip {
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow-x: scroll;
    padding-inline: 20px;
}

.trends-page-content-toggle-button {
    color: #36b4f1;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    min-width: 50px;
}

.complementary-products-no-items-found-container,
.top-performing-products-no-items-found-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
    gap: 10px
}

.trends-page-no-items-found-title {
    font-size: 25px;
    font-weight: 300;
}

.mobile-trends-page-no-items-found-title {
    font-size: 20px;
    font-weight: 300;
}

.trends-page-no-items-icon {
    height: 20px;
    width: 20px;
}

.mobile-trends-page-no-items-icon {
    height: 16px;
    width: 16px;
}

.top-combinations-products-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}

.trends-page-top-combination-plus-icon {
    width: 28px;
    height: 28px;
}

.trends-page-top-combinations-conatainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.trends-page-top-combinations-line-conatainer {
    display: flex;
    align-items: center;
    margin-inline: 20px;
}

.mobile-trends-page-top-combinations-line-conatainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.trends-page-top-combinations-products-conatainer {
    display: flex;
    gap: 15px;
    width: calc(100% - 250px);
    overflow-x: auto;
}

.mobile-trends-page-top-combinations-products-conatainer {
    display: flex;
    width: 100%;
    overflow-x: auto;
    padding-inline: 20px;
}

.trends-page-top-combinations-product-container {
    display: flex;
    align-items: center;
}

.trends-page-horizontal-line {
    width: 100%;
    height: 0;
    border-bottom: 0.5px solid #C4C4C4;
    display:inline-block;
} 

.trends-page-top-combinations-button-conatainer {
    display: flex;
    gap: 20px;
    width: 250px;
    align-items: center;
    justify-content: center;
}
.trends-page-quick-instant-store-button {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 13px;
    padding: 10px;
    background-color: white;
    border: 1.5px solid black;
    cursor: pointer;
    border-radius: 4px;
    height: 35px;
}

.links-page-topbar-button:active {
    background-color: hsl(0, 0%, 85%);
}

.trends-page-complementary-main-product-container {
    margin-inline-start: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px
}

.mobile-trends-page-complementary-main-product-container {
    padding-inline-start: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
}

.complementary-products-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.mobile-complementary-products-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: start;
}

.trends-page-complementary-main-product-title-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 200px;
    justify-content: space-between;
    align-items: center;
}

.mobile-trends-page-complementary-main-product-title-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: calc(100% - 200px);
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.trends-page-complementary-switch-product-button-conatainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
}


.trends-page-complementary-main-product-title {
    width: 85%;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.trends-page-complementary-main-product-swap-icon {
    width: 25px;
    height: 25px;
    stroke-width: 0.5px;
}

.trends-page-complementary-main-button-product-swap-icon {
    width: 15px;
    height: 15px;
}

.trends-page-complementary-complementary-products-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
}

.mobile-trends-page-complementary-complementary-products-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    width: 100%;
}

.trends-page-complementary-line-conatainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}

.mobile-trends-page-complementary-line-conatainer { 
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow-x: auto;
    padding-inline-start: 20px;
}

.trends-page-complementary-product-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: start;
}

.trends-page-complementary-button-conatainer {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.trends-page-quick-complementary-instant-store-button {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 11px;
    padding: 5px;
    background-color: white;
    border: 1.5px solid black;
    cursor: pointer;
    border-radius: 4px;
    height: 25px;
}

.trends-page-complementary-swap-product-swap-button {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 11px;
    padding: 7px 10px;
    background-color: #4372c4;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.trends-page-complementary-swap-product-swap-button:active {
    background-color: hsl(218, 52%, 62%);;
}

.complementary-products-loader-container {
    width: calc(min(100%, 200px));
    height: 213px;
    display: flex;
    align-items: start;
}

.mobile-complementary-products-loader-container {
    width: 100%;
    height: 213px;
    display: flex;
    align-items: center;
}