.store-orders-table-row-view {
    --store-orders-table-row-view-link-image-height: 80px;
    --store-orders-table-row-view-influencer-image-dimens: 50px;

    margin: auto;
    padding: 16px;
}

.store-orders-table-row-view-total,
.store-orders-table-row-view-items {
    text-align: start;
    font-weight: 500;
    padding: 5px;
}

.store-orders-table-row-view-date-frame {
    display: flex;
    align-items: center;
    gap: 10px;
}

.mobile-store-orders-table-row-view-expand-button-image,
.store-orders-table-row-view-expand-button-image {
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    flex-shrink: 0;
    transition: rotate 200ms ease-out;
}

.store-orders-table-row-view-expand-button-image:hover {
    background-color: #f2f2f2;
}

.mobile-store-orders-table-row-view-expand-button-image.expanded,
.store-orders-table-row-view-expand-button-image.expanded {
    rotate: -180deg;
}

.store-orders-table-row-view-date-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 220px;
}

.store-orders-table-row-view-status {
    width: calc(100% - 20px);
    padding: 3px 5px;
    text-transform: uppercase;
    border-radius: 6px;
    text-align: center;
    font-size: 14px;
}

.store-orders-table-row-view-date,
.store-orders-table-row-view-items,
.store-orders-table-row-view-total {
    font-size: 13px;
}

.mobile-store-orders-table-row-view-source,
.store-orders-table-row-view-source {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0 10px 2px;
    margin: 0;
}

.store-orders-table-row-view-link-image {
    height: var(--store-orders-table-row-view-link-image-height);
    aspect-ratio: 1 / 1.2;
}

.store-orders-table-row-view-link-details {
    height: var(--store-orders-table-row-view-link-image-height);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-around;
}

.store-orders-table-row-view-link-title {
    text-align: start;
    width: 22ch;
    font-weight: 500;
}

.store-orders-table-row-view-influencer-image {
    width: var(--store-orders-table-row-view-influencer-image-dimens);
    height: var(--store-orders-table-row-view-influencer-image-dimens);
}

.store-orders-table-row-view-actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.store-orders-table-row-view-view-in-shopify-button-container {
    background-color: black;
    border-radius: 50%;
    padding: 5px;
}

.store-orders-table-row-view-view-in-shopify-button {
    filter: invert(100%);
    color: black;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.store-orders-table-row-view-view-link-button-placeholder,
.store-orders-table-row-view-view-link-button {
    width: 30px;
    height: 30px;
}

.store-orders-table-row-view-view-link-button {
    cursor: pointer;
}

.mobile-store-orders-table-row-view {
    --store-orders-table-row-view-link-image-height: 60px;
    --store-orders-table-row-view-influencer-image-dimens: 30px;
    
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.mobile-store-orders-table-row-view-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 5px;
}

.mobile-store-orders-table-row-view-order-details {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
}

.mobile-store-orders-table-row-view-items,
.mobile-store-orders-table-row-view-total {
    font-size: 12px;
}

.mobile-store-orders-table-row-view-link-title {
    font-size: 10px;
    width: 13ch;
    font-weight: 400;
    text-align: start;
}

.mobile-store-orders-table-row-view-order-details-date {
    font-size: 10px;
    text-align: start;
}

.mobile-store-orders-table-row-view-order-details-total-summary {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
}

.mobile-store-orders-table-row-view-total-summary-dot {
    width: 5px;
    height: 5px;
    background-color: black;
    border-radius: 50%;
    flex-shrink: 0;
}

.mobile-store-orders-table-row-view-expand-button {
    width: 20px;
    height: 20px;
}

.orders-table-row-view-more-details-loader-container {
    width: 100%;
    height: var(--more-order-details-container-height);
    position: relative;
}
.mobile-orders-table-row-view-more-details,
.orders-table-row-view-more-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px 0;
    background-color: #fafafa;
}
.orders-table-row-view-more-details {
    gap: 15px;
}
.mobile-orders-table-row-view-more-details {
    gap: 10px;
}