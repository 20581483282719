.pricing-plans-page-container {
    height: calc(100dvh - 40px - 20px);
}

.pricing-plans-page-title {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin: 20px 0 40px 0;
    text-transform: capitalize;
}

.pricing-plans-page-plans-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0 20px;
}

@media (max-width: 1200px) {
    .pricing-plans-page-container {
        padding-top: calc(var(--topbar-height) / 2);
        padding-bottom: calc(var(--topbar-height) / 2);
    }

    .pricing-plans-page-plans-container {
        justify-content: start;
        padding-bottom: calc(var(--topbar-height) / 2);
    }
}