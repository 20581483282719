.influencers-page-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 20dvh;
}

.mobile-influencers-page-topbar-container,
.influencers-page-topbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.influencers-page-topbar-container {
    padding: 30px 20px 20px 20px;
}

.mobile-influencers-page-topbar-container {
    flex-direction: column;
    align-items: center;
    padding: 10px 13px;
    background-color: white;
}

.influencers-page-topbar-titles-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}

.influencers-page-topbar-page-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}

.influencers-page-topbar-analytics-time-interval {
    color: #858585;
}

.influencers-page-topbar-subtitle {
    font-size: 16px;
    color: #858585;
}

.mobile-influencers-page-topbar-subtitle {
    font-size: 14px;
    color: #858585;
}

.influencers-page-topbar-title {
    font-size: 28px;
    font-weight: 600;
}

.influencers-page-topbar-add-influencer-button {
    border: 1.5px solid black;
    cursor: pointer;
    border-radius: 4px;
    height: 35px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 13px;
    padding: 10px 20px;
}

.influencers-page-topbar-add-influencer-button {
    background-color: white;
    color: black;
}

.influencers-page-topbar-add-influencer-button:active {
    background-color: hsl(0, 0%, 85%);
}

.mobile-influencers-page-content {
    height: calc(100vh - var(--mobile-dashboard-topbar-height) - 20px);
    padding: 10px;
}

.influencers-page-content.no-influencers-content {
    height: 500px;
}

.mobile-influencers-page-content.no-influencers-content {
    height: 350px;
}

.influencers-page-content {
    padding: 10px;
}

.mobile-influencers-page-content.no-influencers-content {
    padding: 10px;
}

.mobile-influencers-page-content-influencers-list,
.influencers-page-content-influencers-list {
    display: grid;
    padding-top: 25px;
}

.influencers-page-content-influencers-list {
    gap: 15px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.mobile-influencers-page-content-influencers-list {
    gap: 5px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-bottom: var(--page-bottom-padding);
}

.influenceres-page-no-influencers-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20dvh;
}