.analytics-page-container,
.mobile-analytics-page-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.mobile-analytics-page-container {
    padding-top: 91px;
    gap: 0px;
}

.mobile-analytics-page-charts-container,
.analytics-page-charts-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.analytics-page-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 20px 0 40px 0;
}

.mobile-analytics-page-title-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px;
    position: fixed;
    z-index: 80;
    top: 40px;
    left: 0;
    width: 100vw;
    background-color: white;
}

.mobile-analytics-page-title-container::after {
    content: "";
    display: block;
    background-color: #cccccc;
    height: 1px;
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.analytics-page-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
}

.mobile-analytics-page-overall-statistics {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;
    padding-bottom: 10px;
    padding-inline: 20px;
}

.analytics-page-overall-statistics {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 15px;
}