.shopboard-social-media-post-products-tag-container,
.mobile-shopboard-social-media-post-products-tag-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mobile-shopboard-social-media-post-products-tag-container {
    gap: 5px;
}

.shopboard-social-media-post-products-tag-title-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.shopboard-social-media-post-products-tag-title {
    font-size: 15px;
    color: #282828;
}

.mobile-shopboard-social-media-post-products-tag-title {
    font-size: 12px;
    color: #282828;
}

.shopboard-social-media-post-products-tag-tag-products-button {
    width: 17px;
    height: 17px;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    z-index: 210;
}