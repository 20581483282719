.add-collections-dialog-collection-container {
    height: fit-content;
}

.add-collections-dialog-collection-image-frame {
    width: 100%;
    aspect-ratio: 1 / 1.5;
    position: relative;
    cursor: pointer;
}

.add-collections-dialog-collection-image {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 90;
    top: 0;
    left: 0;
    cursor: pointer !important;
}

.mobile-add-collections-dialog-collection-title,
.add-collections-dialog-collection-title {
    color: white;
    font-weight: 500;
    position: absolute;
    bottom: 35px;
    left: 10px;
    z-index: 95;
}

.add-collections-dialog-collection-title {
    font-size: 20px;
}

.mobile-add-collections-dialog-collection-title {
    font-size: 13px;
}

.add-collections-dialog-collection-selected-v-mark {
    background-color: #81aa64;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    z-index: 96;
    right: 7px;
    top: 7px;
}

.add-collections-dialog-collection-products-count {
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;
    background-color: rgb(0 0 0 / 60%);
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    padding: 3px 0;
    text-align: center;
    font-size: 12px;
}