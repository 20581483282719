.user-strip {
    --user-menu-image-height: 34px;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cccccc;
    padding: 8px 10px;
    min-height: var(--user-menu-image-height);
    cursor: pointer;
}

.user-strip:hover,
.user-strip:active {
    border-color: #1f7be6;
}

.user-strip.minimized {
    border: none;
    padding: 8px 0px;
}

.user-strip-data {
    max-width: calc(100% - 20px);
    display: flex;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
}

.user-strip-data.minimized {
    width: 100%;
    height: 100%;
    flex-grow: initial;
    max-width: initial;
}

.user-strip-username {
    max-width: calc(100% - 20px);
    color: grey;
    font-size: 15px;
}

.user-menu-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.user-menu-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.user-menu-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 20px 10px 20px;
}

.user-menu-section-title {
    font-size: 14px;
    color: rgb(28, 43, 51);
    font-weight: 600;
}

.user-menu-button {
    background-color: #f8f9fb;
    border: 1px solid #e3e4e6;
    color: #474f55;
    padding: 10px 15px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

a.user-menu-button:hover {
    color: #474f55;
}

.user-strip-business-logo {
    height: var(--user-menu-image-height);
    aspect-ratio: 1;
    flex-shrink: 0;
    cursor: pointer !important;
}

.user-strip-business-logo.minimized {
    width: 100%;
}

.user-strip-user-initials {
    height: 100%;
    border-radius: 50%;
    background-color: black;
    color: white;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}

.user-menu-privacy-terms-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-menu-privacy-terms-button {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
}

.user-menu-section-list {
    width: 100%;
}

.user-menu-buttons-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 20px 20px 20px;
}