.appearance-page-container {
    margin: 20px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
}

.mobile-appearance-page-container {
    display: flex;
    flex-direction: column;
    padding: 45px 10px 0 10px;
}

.appearance-page-title {
    text-align: start;
    margin-bottom: 20px;
}

.appearance-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.appearance-page-store-details-section-store-name-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.appearance-page-store-details-section-store-name-value-container {
    background-color: var(--subsection-input-background-color);
    padding: 7px 11px;
    
}

.appearance-page-store-details-section-store-name-value {
    color: #525876;
    font-size: 15px;
    font-weight: 500;
}

.appearance-page-store-details-section-update-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 50px;
}

.appearance-page-store-details-section-update-logo-add-logo-container {
    width: 65%;
    aspect-ratio: 4 / 1;
}

.mobile-appearance-page-store-details-section-update-logo-add-logo-container {
    width: 100%;
    aspect-ratio: 4 / 1;
}

.appearance-page-store-details-section-update-logo-image-container {
    width: 100%;
    height: 100%;
}

.appearance-page-store-details-section-update-logo-button,
.appearance-page-store-details-section-remove-button {
    width: 100%;
}

.appearance-page-store-details-section-update-logo-button {
    color: #1F2535;
    border: 1px solid #1F2535;
}

.appearance-page-shop-website-section-save-button,
.mobile-appearance-page-shop-website-section-save-button,
.appearance-page-shipping-and-returns-section-save-button,
.mobile-appearance-page-shipping-and-returns-section-save-button,
.appearance-page-link-style-section-save-button,
.mobile-appearance-page-link-style-section-save-button,
.appearance-page-sales-and-discount-style-section-save-button,
.mobile-appearance-page-sales-and-discount-style-section-save-button {
    color: white;
    background-color: black;
}

.mobile-appearance-page-shipping-and-returns-section-save-button {
    width: 100%;
}

.appearance-page-store-details-section-remove-button {
    color: #EE2D2D;
    border: 1px solid #EE2D2D;
}

.appearance-page-store-details-section-update-logo-restriction {
    font-size: 12px;
    color: var(--subsection-title-color);
    white-space: pre-line;
}

.appearance-page-store-details-section-update-logo-buttons-container {
    display: flex;
    justify-content: space-between;
    align-self: start;
    gap: 10px;
    width: 100%;
}

.appearance-page-store-details-section {
    gap: 20px;
}

.appearance-page-shop-website-section-shop-url-input-container,
.appearance-page-shipping-and-returns-section-shop-url-input-container {
    background-color: var(--subsection-input-background-color);
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px;
    width: 100%;
    font-weight: 500;
    color: var(--subsection-title-color);
}

.appearance-page-shop-website-section-shop-url-input,
.appearance-page-shipping-and-returns-section-shop-url-input {
    border: none;
    background-color: transparent;
    width: 100%;
    outline: none;
    font-weight: 500;
    color: var(--subsection-title-color);
}

.appearance-page-shop-website-section-save-button.disabled,
.mobile-appearance-page-shop-website-section-save-button.disabled,
.appearance-page-shipping-and-returns-section-save-button.disabled,
.appearance-page-store-details-section-remove-button.disabled,
.mobile-appearance-page-shipping-and-returns-section-save-button.disabled,
.appearance-page-link-style-section-save-button.disabled,
.mobile-appearance-page-link-style-section-save-button.disabled,
.appearance-page-sales-and-discount-style-section-save-button.disabled,
.mobile-appearance-page-sales-and-discount-style-section-save-button.disabled {
    opacity: 0.3;
    cursor: none;
    pointer-events: none;
}

.appearance-page-shop-website-section-save-button,
.appearance-page-shipping-and-returns-section-save-button,
.appearance-page-link-style-section-save-button,
.appearance-page-sales-and-discount-style-section-save-button {
    min-width: 240px;
    align-self: end;
}

.appearance-page-shop-website-section-save-button:active,
.mobile-appearance-page-shop-website-section-save-button:active,
.appearance-page-shipping-and-returns-section-save-button:active,
.mobile-appearance-page-shipping-and-returns-section-save-button:active,
.appearance-page-link-style-section-save-button:active,
.mobile-appearance-page-link-style-section-save-button:active,
.appearance-page-sales-and-discount-style-section-save-button:active,
.mobile-appearance-page-sales-and-discount-style-section-save-button:active {
    background-color: hsl(0, 0%, 20%);
}

.appearance-page-store-details-section-remove-button:active,
.appearance-page-store-details-section-update-logo-button:active {
    background-color: hsl(0, 0%, 90%);
}

.appearance-page-store-details-section-update-logo-add-logo-restriction-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    width: 100%;
}

.appearance-page-store-details-section-store-logo-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.appearance-page-store-details-section-remove-button,
.appearance-page-store-details-section-update-logo-button,
.appearance-page-shop-website-section-save-button,
.mobile-appearance-page-shop-website-section-save-button,
.appearance-page-shipping-and-returns-section-save-button,
.mobile-appearance-page-shipping-and-returns-section-save-button,
.appearance-page-link-style-section-save-button,
.mobile-appearance-page-link-style-section-save-button,
.appearance-page-sales-and-discount-style-section-save-button,
.mobile-appearance-page-sales-and-discount-style-section-save-button {
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: center;
    cursor: pointer;
}

.appearance-page-store-details-section-remove-button,
.appearance-page-store-details-section-update-logo-button {
    background-color: white;
}

.appearance-discount-style-container,
.appearance-checkout-button-style-container {
    display: flex;
    width: 100%;
}

.appearance-discount-style-fields {
    display: flex;
    flex-direction: column;
}

.appearance-discount-style-fields-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.appearance-discount-style-fields,
.appearance-checkout-button-style-fields,
.appearance-sales-and-discount-strip-style-fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.appearance-discount-style-preview {
    width: 50%;
}

.appearance-preview {
    width: 100%;
    display: flex;
    justify-content: center;
}

.appearance-preview-frame {
    width: 75%;
    height: 75%;
}

.appearance-preview-element {
    margin: 0 auto;
    width: fit-content;
}

.appearance-checkout-button-preview-element {
    color: white;
    font-size: 15px;
    border: 1px solid;
    padding: 10px 20px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.appearance-checkout-button-preview-element-dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
}

.appearance-discount-preview-element {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0;
}

.appearance-discount-preview-discount-original-price {
    font-size: 15px;
    color: #d0d0d0;
    text-decoration: line-through;
}

.appearance-discount-preview-discount-price {
    font-size: 15px;
    color: black;
}

.appearance-discount-preview-discount-price-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.appearance-discount-preview-discount-percentage {
    padding: 3px;
    font-size: 12px;
    font-weight: 300;
}

.appearance-sales-and-discount-strip-style-sentences-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.appearance-sales-and-discount-strip-style-colors-container {
    width: 50%;
}

.mobile-appearance-sales-and-discount-strip-style-colors-container {
    width: 100%;
}

.appearance-discount-enabled-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.appearance-page-sales-and-discount-strip-style-section {
    display: flex;
}

.appearance-sales-and-discount-strip-style-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.appearance-sales-and-discount-strip-preview {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.appearance-sales-and-discount-strip-preview-frame {
    width: 100%;
}

.appearance-subsection-description-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.appearance-subsection-description {
    color: #81828e;
    white-space: pre-wrap;
}

.appearance-page-preorder-style-section {
    display: flex;
}

.appearance-page-preorder-style-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.appearance-page-preorder-style-text-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}