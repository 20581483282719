.shopboard-social-status {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}

.shopboard-social-status-title {
    font-size: 16px;
}

.shopboard-social-status-content {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.shopboard-social-status-connect-button {
    text-decoration: underline;
    color: #3b3bff;
    cursor: pointer;
}

.shopboard-social-status-loader-container {
    width: 100%;
}

.shopboard-social-status-username-count {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    height: 40px;
    width: 100%;
}