.mobile-influencer-item-container,
.influencer-item-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    border: 1px solid hsl(200, 27%, 85%);
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    transition: box-shadow 200ms ease-out;
}

.influencer-item-container:hover {
    box-shadow: 0 0 5px 3px #cccccc;
}

.influencer-item-image {
    width: 70%;
    aspect-ratio: 1;
    margin: 0 auto;
    border: 1px solid #cccccc;
    border-radius: 50%;
}

.influencer-item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.influencer-item-analytics {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.influencer-item-analytics-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.influencer-item-analytics-section-title {
    font-size: 12px;
    font-weight: 300;
    color: #9599ae;
}

.influencer-item-analytics-section-value-container {
    position: relative;
    height: 25px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.influencer-item-analytics-section-value {
    font-size: 21px;
    font-weight: 300;
    text-align: center;
}