.free-trial-strip {
    width: 100%;
    background-color: #4372c4;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 1310;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    translate: 0 calc(var(--dashboard-topbar-height) * -1);
    transition: translate 300ms ease-out;
}

.free-trial-strip.shown {
    translate: 0 0;
    z-index: 1205;
}

.free-trial-strip-content {
    height: 100%;
    margin: 0 auto;
    background-color: transparent;
    padding: 15px 10px;
    display: flex;
    gap: 50px;
    align-items: center;
}

.free-trial-strip-title-image {
    width: 25px;
    height: 25px;
}

.free-trial-strip-titles-section,
.free-trial-strip-buttons-section {
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    font-size: 15px;
}

.free-trial-strip-upgrade-button {
    font-size: 14px;
    padding: 4px 30px;
    cursor: pointer;
    border-radius: 20px;
    color: #4372c4;
    background-color: white;
}

.free-trial-strip-upgrade-button:active {
    background-color: hsl(0, 0%, 84%);
}

.free-trial-strip-logo-image {
    width: 130px;
    aspect-ratio: 5 / 2.5;
}