.progress-dialog-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 15px;
    position: relative;
}

.progress-dialog-progress-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
}

.progress-dialog-progress-title {
    font-size: 23px;
}

.progress-dialog-progress-text {
    font-size: 14px;
}

.progress-dialog-close-button {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
    cursor: pointer;
}