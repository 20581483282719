.dont-have-account-container {
    font-size: 15px;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 5px;
}

.dont-have-account-sign-up-button {
    cursor: pointer;
    text-decoration: underline;
    color: var(--login-signup-button-background-color);
}