.minimized-add-button-container {
    position: relative;
}

.minimized-add-button-image {
    cursor: pointer;
    transition: scale 200ms ease-out, opacity 200ms ease-out;
}

.minimized-add-button-image:active {
    scale: 0.9;
    opacity: 0.8;
}