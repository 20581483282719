.orders-table-header-view {
    margin: auto;
    padding: 16px;
}

.orders-table-header-view-product {
    display: flex;
    align-items: center;
    gap: 10px;
}

.orders-table-header-view-product-image {
    width: 20px;
    height: 20px;
}

.orders-table-header-view {
    font-size: 14px;
}

.orders-table-header-view-total-price,
.orders-table-header-view-total-products-count {
    padding: 5px;
}