.mobile-option-value-mapper-value-container,
.option-value-mapper-value-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 25px;
}

.mobile-option-value-mapper-value-container {
    gap: 10px;
}

.mobile-option-value-mapper-value-name-section,
.option-value-mapper-value-name-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.mobile-option-value-mapper-value-name-section {
    flex-grow: 1;
}

.mobile-option-value-mapper-value-name-section-title,
.option-value-mapper-value-name-section-title {
    width: 100%;
    font-size: 14px;
}

.mobile-option-value-mapper-value-name-section-title {
    width: 45vw;
    font-size: 13px;
}

.option-value-mapper-value-name-section-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.mobile-option-value-mapper-value-value-section,
.option-value-mapper-value-value-section {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
}

.mobile-option-value-mapper-value-value-section {
    width: fit-content;
}

.mobile-option-value-mapper-value-value-section-input,
.option-value-mapper-value-value-section-input {
    padding: 3px;
    background-color: #f6f6f4;
    border-radius: 5px;
    border: 2px solid transparent;
    flex-grow: 1;
}

.mobile-option-value-mapper-value-value-section-input {
    width: 100px;
    flex-grow: 0;
}

.mobile-option-value-mapper-value-value-section-input.error,
.option-value-mapper-value-value-section-input.error {
    border-color: red;
}

.option-value-mapper-value-value-section-status-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}