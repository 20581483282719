.mobile-home-page-container,
.home-page-container {    
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.home-page-container.no-store-connected {
    height: calc(100dvh - var(--dashboard-topbar-height));
    padding-top: var(--dashboard-topbar-height);
}

.mobile-home-page-container.no-store-connected {
    height: calc(100dvh - var(--mobile-dashboard-topbar-height));
    padding-top: var(--mobile-dashboard-topbar-height);
}

.home-page-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.home-page-logo-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.home-page-logo-placeholder,
.home-page-logo {
    width: 75px;
    aspect-ratio: 1;
}

.home-page-logo-placeholder {
    border: 1px solid #cccccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    color: white;
    background-color: var(--dashboard-topbar-initials-circle-background-color);
    text-transform: uppercase;

}

.home-page-header-username {
    font-size: 18px;
}

.mobile-home-page-header-create-buttons,
.home-page-header-create-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.mobile-home-page-header-create-buttons {
    flex-direction: column;
}

.mobile-home-page-header-create-buttons-row {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
}

.mobile-home-page-header-button,
.home-page-header-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    background-color: #e6e8f0;
    border-radius: 6px;
    padding: 7px 10px;
    cursor: pointer;
}

.home-page-header-button {
    min-width: var(--home-page-header-button-width);
}

.mobile-home-page-header-button {
    width: 100%;
}

.home-page-header-button-image {
    width: 15px;
    height: 15px;
}

.mobile-home-page-header-button-text,
.home-page-header-button-text {
    font-size: 14px;
}

.home-page-header-create-link-button {
    color: white;
    background-color: #4372c4;
    min-width: calc(var(--home-page-header-button-width) * 2);
}

.home-page-header-create-link-button:active {
    background-color: hsl(218, 52%, 62%);
}

.home-page-header-create-short-button:active {
    background-color: hsl(228, 25%, 87%);
}

.home-page-recent-activities-content {
    background-color: #f2f2f2;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    box-shadow: 0 0 10px 2px #cccccc;
}

.home-page-recent-activities-content-header {
    display: flex;
    align-items: center;
    gap: 15px;
}

.home-page-recent-activities-content-header-tab {
    border: 1px solid transparent;
    background-color: transparent;
    padding: 8px 10px;
    min-width: 90px;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
}

.home-page-recent-activities-content-header-tab.selected {
    background-color: #dae3f2;
    color: #0c75c2;
    font-weight: 500;
}

.home-page-recent-activities-content-header-tab:not(.selected):hover {
    border: 1px solid #dae3f2;
}

.home-page-recent-activities-content-body {
    width: 100%;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home-page-recent-activities-content-display {
    width: 100%;
    position: relative;
}

.home-page-recent-activities-content-display.no-items {
    height: 100%;
}

.home-page-recent-activities-content-see-all-button {
    border: 1px solid #d7d5d6;
    border-radius: 5px;
    background-color: #e6e8f0;
    padding: 6px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
}

.home-page-recent-activities-content-see-all-button:active {
    background-color: hsl(228, 25%, 87%);
}

.mobile-home-page-recent-activities-content-display-loader-container,
.home-page-recent-activities-content-display-loader-container {
    width: 100%;
    height: 300px;
}

.home-page-recent-activities-content-display-loader-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 95;
}

.mobile-home-page-recent-activities-content-display-loader-container {
    height: 250px;
}

.home-page-recent-activities-content-display-items {
    display: grid;
}

.home-page-recent-activities-content-display-items.no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    background-color: white;
}

.home-page-recent-activities-content-display-items.instants,
.home-page-recent-activities-content-display-items.shorts,
.home-page-recent-activities-content-display-items.influencers {
    gap: 15px;
    height: 100%;
}

.home-page-recent-activities-content-display-items.instants {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.home-page-recent-activities-content-display-items.shorts {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.home-page-recent-activities-content-display-items.influencers {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.home-page-recent-activities {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.mobile-home-page-recent-activities-content {
    display: grid;
    gap: 10px;
}

.mobile-home-page-recent-activities-content-no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
}

.mobile-home-page-recent-instants {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.mobile-home-page-recent-shorts {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.mobile-home-page-recent-influencers {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.home-page-task-image {
    margin-top: -20px;
    width: 190px;
    height: 100px;
}

.home-page-no-recent-activity-image {
    width: 50px;
    height: 50px;
}