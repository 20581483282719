.user-menu-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-menu-list-item-data {
    display: flex;
    align-items: center;
    gap: 10px;
    width: calc((100% - 40px) * 0.75);
}

.user-menu-list-item-data.stretch {
    width: calc(100% - 40px);
}

.user-menu-list-item-titles-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.user-menu-list-item-title {
    font-size: 15px;
    color: black;
    font-weight: 600;
    width: 100%;
}

.user-menu-list-item-subscription-plan {
    font-size: 12px;
    color: black;
}

.user-menu-section-list-item-image-container {
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    padding: 3px;
}

.user-menu-section-list-item-image {
    padding: 2px;
}

.user-menu-section-list-item-image-container.merchant-logo {
    width: 100px;
    aspect-ratio: 1.3 / 1;
}

.user-menu-list-item-button-container {
    width: calc((100% - 40px) * 0.25);
}

.user-menu-list-item-button {
    padding: 10px 3px;
    font-size: 14px;
    text-align: center;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #cccccc;
    cursor: pointer;
}

.user-menu-list-item-button:active {
    background-color: hsl(0, 0%, 80%)
}