.dashboard-sidebar-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 25px;
    padding: 15px;
    height: 100%;
}

.dashboard-sidebar-image {
    width: 130px;
    aspect-ratio: 7 / 2.5;
}

.dashboard-sidebar-image.sidebar-closed {
    width: 100%;
    aspect-ratio: 1 / 1;
}

.dashboard-sidebar-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
}

.dashboard-sidebar-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    width: 100%;
}

.dashboard-sidebar-button {
    color: hsl(69, 7%, 40%);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    padding: 8px 20px;
    width: 100%;
    border-radius: 5px;
    transition: background-color 200ms ease-out, color 200ms ease-out;
}

.dashboard-sidebar-button-link {
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    font-size: 15px;
    cursor: pointer;
    border-radius: 20px;
    color: white;
    background: linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255));
    width: fit-content;
    max-width: 100%;
}

.dashboard-sidebar-button.sidebar-closed {
    padding: 8px;
}

.dashboard-sidebar-button.selected {
    color: white;
    background-color: black;
}

.dashboard-sidebar-button.disabled {
    color: hsl(69, 7%, 80%);
    cursor: none;
    pointer-events: none;
}

.dashboard-sidebar-button:not(.selected):hover {
    background-color: #F0F0F0;
}


.dashboard-sidebar-button-label.sidebar-closed {
    opacity: 0;
}

.dashboard-sidebar-button-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.dashboard-sidebar-custom-button:not(.dashboard-sidebar-color-button) path {
    fill: hsl(69, 7%, 40%);
}

.dashboard-sidebar-color-button path {
    fill: hsl(210, 100%, 65%);
}

.dashboard-sidebar-color-button-image {
    background: radial-gradient(hsl(210, 91%, 83%) 50,white);
}

.dashboard-sidebar-custom-button.disabled path {
    fill: hsl(69, 7%, 80%);
}

.dashboard-sidebar-button:not(.dashboard-sidebar-color-button).selected > .dashboard-sidebar-button-image:not(.dashboard-sidebar-settings-button-image, .dashboard-sidebar-analytics-button-image, .dashboard-sidebar-color-button),
.dashboard-sidebar-custom-button:not(.dashboard-sidebar-color-button).selected path {
    fill: white;
}

.dashboard-sidebar-color-button.selected {
    background-color: rgba(57, 76, 96, 0.15);
}

.dashboard-sidebar-grad-button-label {
    background-image: var(--magic-store-background-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.dashboard-sidebar-color-button-label {
    background-color: hsl(69, 7%, 40%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dashboard-sidebar-horizontal-line {
    width: 90%;
    height: 0;
    border-bottom: 0.5px solid #C4C4C4;
    display:inline-block;
    margin-inline: 5%;
}

.dashboard-sidebar-color-button-with-line-container {
    width: 100%;
}