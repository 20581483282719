.mobile-influencer-insights,
.influencer-insights {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.mobile-influencer-insights {
    margin: 20px 0 0 0;
    width: 100%;
    padding-inline: 20px;
}

.mobile-influencer-insights-row,
.influencer-insights-row {
    display: flex;
    align-items: center;
    gap: 15px;
}

.mobile-influencer-insights-row {
    width: 100%;
}

.influencer-insights-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}