.shopboard-socials {
    height: 50px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.mobile-shopboard-socials {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.shopboard-social-status-image {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
}