.mobile-recent-activity-section {
    display: flex;
    flex-direction: column;
    gap: 25px;
    position: relative;
}

.mobile-recent-activity-section:not(:first-child) {
    padding-top: 30px;
}

.mobile-recent-activity-section:first-child::after,
.mobile-recent-activity-section:not(:first-child)::after {
    content: "";
    display: block;
    height: 13px;
    width: calc(100% + 40px);
    background-color: #e7ede9;
    position: absolute;
    left: -20px;
    z-index: 90;
}

.mobile-recent-activity-section:first-child::before {
    top: -25px;
}

.mobile-recent-activity-section:first-child::after,
.mobile-recent-activity-section:not(:first-child)::after {
    bottom: -40px;
}

.mobile-recent-activity-section-titles-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.mobile-recent-activity-section-title {
    font-size: 18px;
    font-weight: 500;
}

.mobile-recent-activity-section-button {
    font-size: 16px;
    font-weight: 500;
}

.mobile-recent-activity-section-button {
    color: #0095f6;
}

.mobile-recent-activity-section-content {
    position: relative;
}