.mobile-date-picker-container,
.date-picker-container {
    display: flex;
}

.date-picker-container {
    flex-direction: row;
}

.mobile-date-picker-container {
    flex-direction: column;
}

.mobile-date-picker-predefined-date-intervals,
.date-picker-predefined-date-intervals {
    height: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.date-picker-predefined-date-intervals {
    width: 150px;
    padding-top: 15px;
    padding-inline-start: 5px;
}

.mobile-date-picker-predefined-date-intervals {
    width: 100%;
    padding-top: 5px;
}

.mobile-date-picker-selection-panel,
.date-picker-selection-panel {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
}

.date-picker-selection-panel {
    padding: 10px;
}

.mobile-date-picker-selection-panel {
    padding-inline: 10px;
}

.date-picker-bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.date-picker-status-bar {
    font-size: 15px;
}

.date-picker-buttons {
    display: flex;
    align-items: center;
    gap: 5px;
}

.date-picker-cancel-button,
.date-picker-update-button {
    padding: 10px 12px;
    border-radius: 5px;
    cursor: pointer;
}

.date-picker-cancel-button {
    color: black;
    border: 1px solid #e8eaee;
    background-color: #f8f9fb;
}

.date-picker-cancel-button:active {
    background-color: hsl(220, 27%, 90%);
}

.date-picker-update-button {
    color: white;
    border: none;
    background-color: #0878be;
}

.date-picker-update-button:active {
    background-color: hsl(203, 92%, 31%);
}

.date-picker-update-button.disabled {
    opacity: 0.4;
    pointer-events: none;
}

.date-picker-top-bar {
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 10px;
}

.date-picker-top-bar::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: rgb(196, 194, 194);
    position: absolute;
    left: 0;
    bottom: -5px;
    z-index: 50;
}

.date-picker-top-bar-cancel-button,
.date-picker-top-bar-update-button,
.date-picker-top-bar-status {
    text-align: center;
}

.date-picker-top-bar-status {
    font-size: 17px;
    font-weight: 600;
}

.date-picker-top-bar-cancel-button,
.date-picker-top-bar-update-button {
    font-size: 15px;
    font-weight: 500;
}

.date-picker-top-bar-update-button {
    color: hsl(203, 92%, 31%);
}

.date-picker-top-bar-update-button.disabled {
    opacity: 0.4;
    pointer-events: none;
}