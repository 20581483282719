.running-messages-strip-container {
    --running-messages-strip-row-height: 30px;

    width: 100%;
    padding: 10px;
    height: var(--running-messages-strip-row-height);
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-running-message,
.running-message {
    text-align: center;
    animation-name: in-message-anim;
    animation-duration: 350ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

.running-message {
    font-size: 15px;
}

.mobile-running-message {
    font-size: 13px;
}

.running-message.hide {
    visibility: hidden;
}

@keyframes in-message-anim {
    from {
        opacity: 0;
        translate: 0 var(--running-messages-strip-row-height);
    }
    to {
        opacity: 1;
        translate: 0 0;
    }
}

@keyframes out-message-anim {
    from {
        opacity: 1;
        translate: 0 0;
    }
    to {
        opacity: 0;
        translate: 0 calc(var(--running-messages-strip-row-height) * -1);
    }
}