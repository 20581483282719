.expand-hide-button {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.expand-hide-button-text {
    font-size: 14px;
    font-weight: 500;
}

.expand-hide-button-image {
    width: 15px;
    height: 15px;
    transition: rotate 200ms ease-out;
}

.expand-hide-button-image.expanded {
    rotate: -180deg;
}