.shopboard-connect {
    width: 250px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.shopboard-connect-title {
    font-size: 25px;
}

.shopboard-connect-subtitle {
    font-size: 15px;
}