.password-input-container {
    --password-instructions-text-color: #7e7c7c;

    display: flex;
    flex-direction: column;
    gap: 5px;
}

.password-input-instruction {
    font-size: 14px;
    color: var(--password-instructions-text-color);
}