.mobile-paydin-chart-control-section,
.paydin-chart-control-section {
    --chart-control-section-animation-duration: 200ms;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    transition: box-shadow var(--chart-control-section-animation-duration) ease-out;
}

.paydin-chart-control-section:hover {
   box-shadow: 0 0 3px 1px #cccccc;
}

.paydin-chart-control-section-title-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.paydin-chart-control-section-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transition: background-color var(--chart-control-section-animation-duration) ease-out;
}

.mobile-paydin-chart-control-section-title,
.paydin-chart-control-section-title {
    font-weight: 300;
    color: #cecece;
    transition: color var(--chart-control-section-animation-duration) ease-out;
}

.paydin-chart-control-section-title {
    font-size: 16px;
}

.mobile-paydin-chart-control-section-title {
    font-size: 13px;
}

.mobile-paydin-chart-control-section-title.selected,
.paydin-chart-control-section-title.selected {
    color: black;
}

.mobile-paydin-chart-control-section-value,
.paydin-chart-control-section-value {
    color: #cecece;
    transition: color var(--chart-control-section-animation-duration) ease-out;
}

.paydin-chart-control-section-value {
    font-size: 26px;
}

.mobile-paydin-chart-control-section-value {
    font-size: 23px;
}

.mobile-paydin-chart-control-section-value.selected,
.paydin-chart-control-section-value.selected {
    color: black;
}