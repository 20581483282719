.mobile-edit-link-section-add-products-dialog-content,
.edit-link-section-add-products-dialog-content {
    --add-products-dialog-topbar-height: 90px;
    --add-products-dialog-product-list-header-height: 30px;
    --add-products-dialog-product-list-height: 200px;
    --mobile-add-products-dialog-product-list-height: calc(100dvh - 130px);
    --add-products-dialog-item-height: 105px;
    --mobile-add-products-dialog-item-height: 85px;
    --add-products-dialog-products-content-height: 430px;
    --mobile-add-products-dialog-products-content-height: 440px;

    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.edit-link-section-add-products-dialog-content {
    justify-content: space-between;
}

.mobile-edit-link-section-add-products-dialog-content {
    gap: 5px;
}

.mobile-add-products-dialog-details,
.add-products-dialog-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mobile-add-products-dialog-details {
    height: 100dvh;
}

.edit-link-section-add-products-dialog-no-results-found-container,
.edit-link-section-add-products-dialog-loader-container {
    width: 100%;
    height: var(--add-products-dialog-products-content-height);
}

.edit-link-section-add-products-dialog-products,
.mobile-edit-link-section-add-products-dialog-products {
    position: relative;
}

.edit-link-section-add-products-dialog-products-list,
.edit-link-section-add-products-dialog-loader-cell,
.edit-link-section-add-products-dialog-products {
    height: var(--add-products-dialog-products-content-height);
}

.mobile-edit-link-section-add-products-dialog-products-list,
.edit-link-section-add-products-dialog-products-list {
    display: grid;
    gap: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 55;
    overflow: auto;
    padding: 0 10px;
}

.edit-link-section-add-products-dialog-products-list {
    grid-template-columns: repeat(7, minmax(0, 1fr));
    height: 100%;
}

.mobile-edit-link-section-add-products-dialog-loader-cell {
    height: 100%;
}

.mobile-edit-link-section-add-products-dialog-products-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.mobile-edit-link-section-add-products-dialog-loader-cell,
.edit-link-section-add-products-dialog-loader-cell {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 60;
}

.edit-link-section-add-products-dialog-no-results-found-container {
    background-color: white;
}

.edit-link-section-add-products-dialog-no-results-found-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-link-section-add-products-dialog-no-results-found-text {
    font-size: 20px;
    color: #B0B0B0;
    text-align: center;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    z-index: 40;
}

.edit-link-section-add-products-dialog-topbar {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 0 10px;
}

.edit-link-section-add-products-dialog-topbar-done-button {
    color: #0095f6;
    font-weight: 600;
}

.mobile-edit-link-section-add-products-dialog-topbar-title-container,
.edit-link-section-add-products-dialog-topbar-title-container {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px 0;
}

.mobile-edit-link-section-add-products-dialog-topbar-title-container {
    justify-content: space-between;
}

.edit-link-section-add-products-dialog-topbar-titles {
    display: flex;
    align-items: center;
    gap: 10px;
}

.edit-link-section-add-products-dialog-topbar-title {
    font-size: 18px;
    font-weight: 500;
}

.add-products-dialog-topbar-selected-products-count {
    font-size: 14px;
    color: #717171;
}

.edit-link-section-add-products-dialog-topbar-search-box {
    padding: 5px 10px;
    border: 1px solid hsl(225, 18%, 90%);
    display: flex;
    align-items: center;
    gap: 10px;
    width: 300px;
    height: 40px;
    margin: auto 0;
    border-radius: 3px;
}

.edit-link-section-add-products-dialog-topbar-search-box-input {
    border: none;
    outline: none;
    width: 100%;
}

.edit-link-section-add-products-dialog-topbar-search-box-input::placeholder {
    font-size: 15px;
    font-weight: 500;
    color: #b8bcd2;
}

.add-products-dialog-status-bar {
    width: 100%;
    padding: 5px 10px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    gap: 10px;
}

/* .add-products-dialog-filters-status-container {
    position: relative;
    padding: 0 10px;
} */

/* .add-products-dialog-filters-status {
    padding: 5px 10px;
    background-color: #b8b8b8;
    color: white;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}

.add-products-dialog-filters-status-text {
    font-weight: 300;
    font-size: 15px;
}

.add-products-dialog-filters-status-image {
    width: 20px;
    height: 20px;
} */

/* .add-products-dialog-filters-menu {
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 0 5px 3px #cccccc;
    position: absolute;
    top: 40px;
    left: 10px;
    z-index: 80;
    background-color: white;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 15px;
}

.add-products-dialog-filters-menu-filters {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.add-products-dialog-filters-menu-filters-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.add-products-dialog-filters-menu-filters-section {
    font-size: 14px;
}

.add-products-dialog-filters-menu-filters-list {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    padding-inline-start: 10px;
}

.add-products-dialog-filters-menu-filter-label {
    font-size: 14px;
}

.add-products-dialog-filters-menu-clear-button {
    font-size: 14px;
    color: #4b91ec;
    cursor: pointer;
} */

.edit-link-section-add-products-dialog-done-button-container {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 15px;
}

.edit-link-section-add-products-dialog-done-button {
    padding: 10px 20px;
    background-color: black;
    color: white;
    text-transform: uppercase;
    text-align: center;
    width: 200px;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 14px;
    cursor: pointer;
}