.MuiTablePagination-displayedRows {
    margin-block-end: 0;
}

.mobile-paydin-table-no-items-container,
.paydin-table-no-items-container,
.mobile-paydin-table-loader-container,
.paydin-table-loader-container {
    width: 100%;
    background-color: white;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 200;
    border-radius: 15px;
}

.paydin-table-no-items-container,
.paydin-table-loader-container {
    top: 65px;
}

.mobile-paydin-table-no-items-container,
.mobile-paydin-table-loader-container {
    top: 0;
}

.paydin-table-no-items {
    position: absolute;
    inset: 0;
    margin: auto;
}