.mobile-add-influencer-select-container,
.add-influencer-select-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    flex-shrink: 0;
}

.mobile-add-influencer-select-content,
.add-influencer-select-content {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: white;
    padding: 20px;
}

.add-influencer-select-title {
    font-size: 14px;
    color: #7f859e;
}

.add-influencer-select-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.add-influencer-select-no-influencer-option {
    width: 100%;
    height: 50px;
    display: flex;
}

.add-influencer-select-no-influencer-option-text {
    height: fit-content;
    margin: auto 0;
}

.mobile-add-influencer-select-placeholder-element,
.add-influencer-select-placeholder-element {
    color: hsl(231, 17%, 39%);
    font-weight: 500;
    font-size: 15px;
}

.add-influencer-select-no-influencers-placeholder-element {
    font-size: 16px;
}