.shopboard-connect-button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 13px;
    border-radius: 25px;
    padding: 10px 35px;
    cursor: pointer;
}

.shopboard-connect-button-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    filter: invert(100%);
}

.shopboard-connect-button-text {
    font-size: 15px;
    color: white;
}