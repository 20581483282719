.mobile-account-page-container,
.account-page-container {
    margin: 20px;
    display: flex;
    flex-direction: column;
}

.account-page-container {
    margin: 30px 20px 20px 20px;
    padding-bottom: 100px;
}

.mobile-account-page-container {
    margin: 0;
    padding: 45px 10px 0 10px;
}

.account-page-title {
    text-align: start;
    margin-bottom: 30px;
}

.account-page-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.account-page-my-information-section-content {
    gap: 15px !important;
}

.account-page-my-information-section-content,
.account-page-account-actions-section-content,
.account-page-danger-zone-section-content {
    border-radius: 10px !important;
}

.account-page-my-information-section-input {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #cccccc;
}

.mobile-account-page-my-information-section-save-details-button,
.account-page-my-information-section-save-details-button,
.account-page-store-profile-section-force-product-sync-button {
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    background-color: white;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: center;
    cursor: pointer;
    min-width: 240px;
    align-self: end;
    color: #1F2535;
    border: 1px solid #1F2535;
}

.mobile-account-page-my-information-section-save-details-button {
    width: 100%;
}

.mobile-account-page-my-information-section-save-details-button.disabled,
.account-page-my-information-section-save-details-button.disabled {
    opacity: 0.3;
    cursor: none;
    pointer-events: none;
}

.mobile-account-page-my-information-section-save-details-button:active,
.account-page-my-information-section-save-details-button:active,
.account-page-store-profile-section-force-product-sync-button:active {
    background-color: hsl(0, 0%, 90%);
}

.account-page-current-account-section-plan-details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.account-page-current-account-section-plan-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
}

.account-page-current-account-section-username,
.account-page-current-account-section-plan-details-plan-name,
.account-page-store-profile-section-store-name {
    font-size: 14px;
    color: #525876;
    font-weight: 600;
}

.account-page-current-account-section-plan-details-title {
    font-size: 15px;
}

.mobile-account-page-current-account-section-upgrade-button,
.account-page-current-account-section-upgrade-button {
    font-size: 15px;
    align-self: flex-end;
    background-color: black;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 300;
    cursor: pointer;
    padding: 13px 25px;
    text-align: center;
}

.mobile-account-page-current-account-section-upgrade-button {
    width: 100%;
}

.mobile-account-page-current-account-section-upgrade-button:active,
.account-page-current-account-section-upgrade-button:active {
    background-color: hsl(0, 0%, 20%);
}

.account-page-store-profile-section-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.account-page-store-profile-section-content-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    font-size: 14px;
}

.account-page-store-profile-section-logo-name-container {
    display: flex;
    align-items: center;
    gap: 15px;
}

.account-page-store-profile-section-button {
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    background-color: white;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: center;
    cursor: pointer;
    min-width: 140px;
    color: #1F2535;
    border: 1px solid #1F2535;
}

.account-page-store-profile-section-spacer {
    width: 100%;
    height: 1px;
    background-color: #B7BCD2;
}

.account-page-store-profile-section-button:active {
    background-color: hsl(0, 0%, 90%);
}

.account-page-store-profile-section-buttons-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.account-page-store-profile-section-logo-container {
    width: 50px;
    height: 50px;
    background-color: #DCDEE7;
    border-radius: 7px;
}

.account-page-store-profile-section-buttons-section {
    display: flex;
    gap: 20px;
    width: 100%;
}

.mobile-account-page-danger-zone-section-delete-account-button,
.account-page-danger-zone-section-delete-account-button,
.account-page-store-profile-section-disconnect-button,
.account-page-social-section-social-status-disconnect-button {
    color: #EE2D2D;
    border: 1px solid #EE2D2D;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    background-color: white;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: center;
    cursor: pointer;
}

.mobile-account-page-danger-zone-section-delete-account-button {
    width: 100%;
}

.account-page-danger-zone-section-instruction,
.account-page-store-profile-section-disconnection-instruction,
.account-page-store-profile-section-force-product-sync-instruction {
    font-size: 12px;
    font-weight: 300;
    color: #6B718E;
    text-align: start;
    height: fit-content;
    margin: auto 0;
}

.account-page-store-profile-section-force-product-sync-button {
    align-self: center;
}

.account-page-account-actions-section-reset-password-button {
    align-self: end;
}

.mobile-account-page-account-actions-section-reset-password-button {
    width: 100%;
}

.account-page-danger-zone-section-delete-account-button {
    color: #EE2D2D;
    border: 1px solid #EE2D2D;
    flex-shrink: 0;
}

.account-page-store-profile-section-disconnect-button:active,
.account-page-danger-zone-section-delete-account-button:active {
    background-color: hsl(0, 0%, 90%);
}

.mobile-account-page-account-actions-section-reset-password-button,
.account-page-account-actions-section-reset-password-button,
.account-page-danger-zone-section-delete-account-button,
.account-page-store-profile-section-disconnect-button {
    padding: 13px 25px;
    border-radius: 5px;
    cursor: pointer;
}

.account-page-danger-zone-section-container {
    display: flex;
    align-items: center;
    gap: 15px;
}

.account-page-account-actions-section-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 40px;
}

.account-page-utm-params-subtitle-input-container {
    margin-bottom: 15px;
}

.account-page-influencer-profile-image-container {
    width: 150px;
    aspect-ratio: 1;
    margin-bottom: 20px;
}

.store-settings-page-shop-website-section-subtitle-input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.account-page-merchant {
    display: flex;
    align-items: center;
    gap: 15px;
}

.account-page-merchant-username {
    color: #8a8a8a;
}

.account-page-merchant-logo {
    width: 100px;
    aspect-ratio: 2;
}

.account-page-social-section-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.account-page-social-section-status-image {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
}

.account-page-social-section-social-status-container {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
}

.account-page-social-section-social-status-disconnect-button {
    font-size: 12px;
}