.add-products-filters-status-container {
    position: relative;
}

.add-products-filters-status {
    padding: 5px 10px;
    background-color: #b8b8b8;
    color: white;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}

.add-products-filters-status-text {
    font-weight: 300;
    font-size: 15px;
}

.add-products-filters-status-image {
    width: 20px;
    height: 20px;
}

.add-products-filters-menu {
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 0 5px 3px #cccccc;
    position: absolute;
    top: 40px;
    left: 10px;
    z-index: 80;
    background-color: white;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 15px;
}

.add-products-filters-menu-filters {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.add-products-filters-menu-filters-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.add-products-filters-menu-filters-section {
    font-size: 14px;
}

.add-products-filters-menu-filters-list {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    padding-inline-start: 10px;
}

.add-products-filters-menu-filter-label {
    font-size: 14px;
}

.add-products-filters-menu-clear-button {
    font-size: 14px;
    color: #4b91ec;
    cursor: pointer;
}