.multilink-experiences-table-row-view  {
    margin: auto;
    padding: 16px;
}

.multilink-experiences-table-row-view-total,
.multilink-experiences-table-row-view-items {
    padding: 8px;
}

.multilink-experiences-table-row-view-items,
.multilink-experiences-table-row-view-total {
    font-size: 12px;
}

.multilink-experiences-table-row-view-items,
.multilink-experiences-table-row-view-total {
    text-align: start;
    font-weight: 500;
}

.multilink-experiences-table-row-view-clickable {
    color: var(--multilink-experience-link-button-text-color);
    text-decoration: underline;
    cursor: pointer;
}