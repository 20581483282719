.mobile-influencer-preview-page-container,
.influencer-preview-page-container {
    --mobile-time-select-strip-height: 50px;
    
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    margin-top: var(--content-dialog-topbar-height);
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.influencer-preview-page-container {
    height: calc(var(--content-dialog-height) - var(--content-dialog-topbar-height));
}

.mobile-influencer-preview-page-container {
    height: calc(100dvh - var(--content-dialog-topbar-height));
    padding-top: 50px;
}

.influencer-preview-page-container::-webkit-scrollbar {
    display: none;
}

.mobile-influencer-preview-page-content,
.influencer-preview-page-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mobile-influencer-preview-page-fixed-section {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 200;
    top: var(--content-dialog-topbar-height);
    left: 0;
}

.mobile-influencer-preview-topbar,
.influencer-preview-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.influencer-preview-topbar {
    padding: 20px 0;
}

.mobile-influencer-preview-topbar {
    width: 100%;
    background-color: white;
    padding: 0px 4px;
    position: relative;
}

.mobile-influencer-preview-topbar::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #cccccc;
    width: 100%;
    position: absolute;
    z-index: 90;
    right: 0;
    left: 0;
    bottom: 0;
}

.mobile-influencer-preview-topbar-influencer-username {
    font-weight: 500;
}

.mobile-influencer-preview-topbar-right-section,
.influencer-preview-topbar-right-section {
    display: flex;
    align-items: center;
}

.mobile-influencer-preview-topbar-right-section-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    background-color: white;
    position: relative;
    height: var(--mobile-time-select-strip-height);
}

.mobile-influencer-preview-topbar-right-section-container::after {
    content: "";
    display: block;
    background-color: #B7BCD2;
    height: 1px;
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
}

.influencer-preview-more-options-menu-button {
    width: 40px;
    height: 40px;
    padding: 8px;
    cursor: pointer;
}

.mobile-influencer-preview-influencer-overview,
.influencer-preview-influencer-overview {
    display: flex;
    align-items: center;
    position: relative;
}

.influencer-preview-influencer-overview {
    width: 100%;
    grid-gap: 60px;
    gap: 60px;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-inline-start: 10%;
}

.mobile-influencer-preview-influencer-overview {
    align-items: center;
    width: 100%;
    gap: 30px;
    padding: 10px;
}

.mobile-influencer-preview-influencer-overview::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #B7BCD2;
}

.mobile-influencer-preview-influencer-details,
.influencer-preview-influencer-details {
    display: flex;
    flex-direction: column;
}

.influencer-preview-influencer-details {
    width: 54%;
    gap: 25px;
}

.mobile-influencer-preview-influencer-details {
    width: 65%;
    font-size: 15px;
    gap: 10px;
}

.mobile-influencer-preview-page-tabs-container {
    position: sticky;
    z-index: 200;
    top: 0;
}

.mobile-influencer-preview-influencer-image,
.influencer-preview-influencer-image {
    aspect-ratio: 1;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid #cccccc;
}

.influencer-preview-influencer-image {
    width: 150px;
    align-self: start;
}

.mobile-influencer-preview-influencer-image {
    width: 120px;
}

.influencer-preview-influencer-username {
    font-weight: 500;
}

.mobile-influencer-preview-influencer-analytics,
.influencer-preview-influencer-analytics {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobile-influencer-preview-influencer-analytics {
    margin: 10px auto;
}

.influencer-preview-influencer-analytics-section {
    display: flex;
    gap: 5px;
}

.influencer-preview-influencer-analytics-section-value {
    font-weight: 500;
}

.influencer-preview-influencer-data {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
}

.influencer-preview-influencer-commission-container,
.influencer-preview-influencer-promocode-container,
.influencer-preview-influencer-utm-container {
    display: flex;
    gap: 5px;
}

.influencer-preview-influencer-commission-value {
    font-weight: bold;
}

.influencer-preview-influencer-utm-value {
    font-weight: 500;
}

.influencer-preview-influencer-promocode-value {
    font-weight: 500;
    text-transform: uppercase;
    background-color: #c6c5c5;
    font-size: 14px;
    border-radius: 5px;
    padding: 3px;
}

.influencer-preview-influencer-commission-value {
    font-weight: 500;
}

.mobile-influencer-preview-shorts-list,
.influencer-preview-shorts-list:not(.no-influencer-shorts),
.mobile-influencer-preview-links-list,
.influencer-preview-links-list:not(.no-influencer-links) {
    display: grid;
}

.influencer-preview-influencer-insights {
    padding-bottom: 5dvh;
}

.influencer-preview-shorts-list,
.influencer-preview-links-list {
    gap: 15px;
    padding-inline: 20px;
}

.influencer-preview-links-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.influencer-preview-shorts-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.mobile-influencer-preview-shorts-list,
.mobile-influencer-preview-links-list {
    gap: 5px;
    padding: 10px;
    position: relative;
}

.mobile-influencer-preview-links-list:not(.no-influencer-links) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-bottom: var(--page-bottom-padding);
}

.mobile-influencer-preview-shorts-list:not(.no-influencer-shorts) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding-bottom: var(--page-bottom-padding);
}

.no-influencer-shorts,
.no-influencer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.influencer-preview-more-options-menu-delete-option,
.mobile-influencer-preview-more-options-drawer-delete-option-image {
    color: red;
}

.mobile-influencer-preview-topbar-add-button,
.influencer-preview-topbar-add-button {
    width: 40px;
    height: 40px;
    padding: 9px 0 9px 9px;
}

.influencer-preview-links-shorts-list-container {
    width: 100%;
    position: relative;
    padding-bottom: 10dvh;
}

.influencer-preview-links-shorts-list-loader-container {
    width: 100%;
    height: 250px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 80;
}

.mobile-tabs-top-line,
.tabs-top-line {
    background-color: #B7BCD2;
    height: 1px;
    width: 100vw;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 0;
}

.mobile-infuencer-preview-page-insights-container,
.infuencer-preview-page-insights-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-infuencer-preview-page-insights-container {
    padding: 10px;
}

.influencer-preview-buttons-section {
    display: flex;
    align-items: center;
    gap: 15px;
}

.home-page-header-create-link-button {
    color: white;
    background-color: #4372c4;
    min-width: calc(var(--home-page-header-button-width) * 2);
}

.home-page-header-create-short-button {
    color: black;
    background-color: #e6e8f0;
}

.home-page-header-create-link-button:active {
    background-color: hsl(218, 52%, 62%);
}

.home-page-header-create-short-button:active {
    background-color: hsl(228, 25%, 87%);
}

.home-page-header-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 6px;
    padding: 7px 10px;
    cursor: pointer;
}

.influencer-preview-links-shorts-list-pagination-loader-container {
    height: 60px;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}