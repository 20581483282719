.utm-form-inputs,
.utm-form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.utm-form-container {
    gap: 15px;
}

.utm-form-inputs {
    background-color: white;
    gap: 30px;
}

.utm-form-utm-tag-preview-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.utm-form-utm-tag-preview-title-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.utm-form-utm-tag-preview {
    word-break: break-all;
}

.utm-form-utm-tag-preview-title {
    font-size: 14px;
    font-weight: 600;
}

.utm-form-campaign-input {
    width: 100%;
}

.mobile-utm-form-control-bar-container,
.utm-form-control-bar-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-inline: 20px;
}

.utm-form-switch-label {
    font-size: 15px;
    font-weight: 300;
    color: #595858;   
}