.items-view-section-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;
    position: relative;
    padding: 10px 0;
}

.items-view-section-title {
    font-weight: 500;
    font-size: 16px;
    text-align: start;
    padding-inline-start: 20px;
}

.items-view-section-title.no-padding {
    padding-inline-start: 0;
}

.items-view-section-loader-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 80;
    inset: 0;
    margin: auto;
    background-color: white;
}

.items-view-section-more-items-loader-loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}