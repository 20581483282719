.shopify-help-page-container {
    --shopify-help-page-back-button-container-height: 60px;

    background-color: hsl(165, 3%, 90%);
    margin: 60px 20px 20px 20px;
    padding: 25px 5%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
}

.shopify-help-page-title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
}

.shopify-help-page-section-title {
    text-align: start;
    font-size: 16px;
    width: 100%;
    font-weight: 350;
    margin-top: 20px;
    margin-bottom: 10px;
}

.shopify-help-page-section-image {
    max-width: 1200px;
    max-width: 100%;
    margin: 10px;
}

.shopify-help-page-section-image-container {
    display: flex;
    justify-content: center;
}

.shopify-help-page-back-button-container {
    width: 100%;
    height: var(--shopify-help-page-back-button-container-height);
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 40;
    display: flex;
    align-items: center;
}

.shopify-help-page-back-button {
    width: 25px;
    height: 25px;
    margin-left: 20px;
    cursor: pointer;
}