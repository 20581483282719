.dashboard-data-container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 15px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 3px 3px #cccccc;
}

.dashboard-data-container-title,
.mobile-dashboard-data-container-title {
    font-weight: 600;
}

.dashboard-data-container-title {
    font-size: 16px;
    font-weight: 500;
}

.mobile-dashboard-data-container-title {
    font-size: 14px;
}