
.disconnect-confirm-dialog {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.disconnect-confirm-dialog-titles-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.disconnect-confirm-dialog-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin: 10px 0 25px 0;
    color: #EE2D2D;
}

.disconnect-confirm-dialog-subtitle {
    font-size: 15px;
    white-space: pre-line;
}

.mobile-disconnect-confirm-dialog-buttons,
.disconnect-confirm-dialog-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.mobile-disconnect-confirm-dialog-buttons {
    align-items: start;
    gap: 15px;
}

.disconnect-confirm-dialog-buttons {
    align-items: center;
    justify-content: space-around;
    gap: 25px;
}

.disconnect-confirm-dialog-left-button,
.disconnect-confirm-dialog-right-button {
    padding: 10px 15px;
    flex-grow: 1;
    cursor: pointer;
}

.disconnect-confirm-dialog-right-button {
    border: 1px solid #EE2D2D;
    background-color: white;
    color: #EE2D2D;
}

.disconnect-confirm-dialog-right-button.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: none;
}

.disconnect-confirm-dialog-left-button {
    background-color: black;
    color: white;
}