.product-table-header-view {
    margin: auto;
}

.product-table-header-view-product {
    display: flex;
    align-items: center;
    gap: 10px;
}

.product-table-header-view-product-image {
    width: 20px;
    height: 20px;
}

.product-table-header-view {
    font-size: 14px;
}