.link-item-container,
.mobile-link-item-container {
    padding: 10px;
    border: 1px solid hsl(200, 27%, 85%);
    border-radius: 4px;
    position: relative;
    background-color: white;
    transition: box-shadow 200ms ease-out;
    cursor: pointer;
}

.link-item-container:hover {
    box-shadow: 0 0 5px 3px #cccccc;
}

.link-item-content {
    width: 100%;
    height: 100%;
}

.link-item-details-container,
.mobile-link-item-details-container {
    width: 100%;
}

.link-item-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.link-item-image,
.mobile-link-item-image {
    width: 100%;
    aspect-ratio: 1;
    flex-shrink: 0;
    cursor: pointer;
}

.link-item-details {
    flex: 1 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
    height: max-content;
}

.link-item-created-at-expired-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    position: relative;
}

.link-item-titles-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.link-item-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.link-item-buttons-container {
    display: flex;
    align-items: center;
}

.link-item-details-lower-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
    height: max-content;
}

.link-item-created-at-text,
.mobile-link-item-created-at-text {
    font-weight: 300;
}

.link-item-created-at-text {
    font-size: 12px;
}

.mobile-link-item-created-at-text {
    font-size: 11px;
}

.link-item-title,
.mobile-link-item-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.link-item-title {
    font-size: 14px;
}

.mobile-link-item-title {
    font-size: 13px;
}

.link-item-link-expired-text {
    background-color: #cccccc;
    color: white;
    padding: 2px 5px;
    font-size: 12px;
    width: fit-content;
    font-weight: 300;
}

.link-item-statistics-container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 30px;
    width: 100%;
}

.mobile-link-item-statistics-container {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-inline-end: 10px;
}

.link-item-bottom-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.detail-container {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.link-item-statistics-title,
.link-item-created-at-text,
.mobile-link-item-created-at-text {
    color: #9599ae;
}

.link-item-statistics-title {
    font-size: 12px;
    font-weight: 300;
}

.link-item-statistics-value {
    font-size: 23px;
    font-weight: 300;
}

.mobile-link-item-statistics-value {
    font-size: 19px;
    font-weight: 300;
}

.link-item-copy-link-button-image,
.link-item-statistics-more-options-button,
.mobile-link-item-statistics-more-options-button {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.link-item-copy-link-button-image,
.link-item-statistics-more-options-button,
.mobile-link-item-statistics-more-options-button {
    padding: 6px;
}

.mobile-link-item-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.mobile-link-item-options-drawer-option {
    text-align: center;
    padding: 20px;
}

.mobile-link-item-options-drawer-option:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.mobile-link-item-options-drawer-option:active {
    background-color: hsl(0, 0%, 90%);
}

.mobile-link-item-more-options-menu-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mobile-link-item-more-options-menu-single-item {
    padding: 14px;
    font-size: 15px;
    background-color: var(--link-item-more-options-menu-background-color);
    border-radius: var(--link-item-more-options-menu-border-radius);
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.mobile-link-item-more-options-menu-item-line {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.mobile-link-item-more-options-menu-section {
    width: 100%;
    background-color: var(--link-item-more-options-menu-background-color);
    border-radius: var(--link-item-more-options-menu-border-radius);
}

.mobile-link-item-more-options-menu-section-item {
    text-align: center;
    width: 100%;
    padding: 15px;
    border-radius: 0;
    position: relative;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.mobile-link-item-more-options-menu-section-item-image,
.mobile-link-item-more-options-menu-single-item-image {
    width: 20px;
    height: 20px;
}

.mobile-link-item-more-options-menu-section-item:active,
.mobile-link-item-more-options-menu-single-item:active {
    background-color: hsl(0, 0%, 85%);
}

.mobile-link-item-more-options-menu-section-item:not(:last-child)::after {
    content: "";
    display: block;
    height: 1px;
    background-color: white;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}

.mobile-link-item-more-options-menu-section-item-delete-link {
    color: red;
}

.mobile-link-item-more-options-menu-section-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.mobile-link-item-more-options-menu-section-item:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.link-item-options-menu-delete-option {
    color: red;
}