.dashboard-layout-container {
    padding-bottom: 10px;
    height: 100%;
    margin-left: auto;
}

.mobile-dashboard-layout-container {
    padding-bottom: 0px;
    height: 100vh;
}

.mobile-dashboard-layout-content {
    height: 100%;
}