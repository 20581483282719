.mobile-preview-item-data-section,
.preview-item-data-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 30px;
    background-color: #f6f7fa;
}

.preview-item-data-section {
    padding: 20px;
}

.mobile-preview-item-data-section {
    padding: 0;
    gap: 15px;
}

.preview-item-data-section-title {
    font-size: 20px;
}