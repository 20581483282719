.mobile-button-strip {
    display: grid;
    padding: 8px 8px 0 8px;
}

.mobile-button-strip.bottom-line {
    position: relative;
}

.mobile-button-strip.bottom-line::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #cccccc;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 100;
}

.mobile-button-strip-button {
    color: hsl(69, 7%, 40%);
    font-size: 14px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px 8px;
    height: 60px;
    border-bottom: 2px solid transparent;
}

.mobile-button-strip-button.disabled {
    color: hsl(69, 7%, 80%);
    cursor: none;
    pointer-events: none;
}

.mobile-button-strip-button.selected {
    color: black;
    border-bottom: 2px solid black;
}

.mobile-button-strip-button-text {
    white-space: pre-line;
    text-align: center;
    width: 100%;
}