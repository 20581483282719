.mobile-preview-link-container,
.preview-link-container {
    --link-view-main-frame-min-height: 590px;

    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    height: calc(var(--content-dialog-height) - var(--content-dialog-topbar-height));
    margin-top: var(--content-dialog-topbar-height);
    overflow-y: auto;
}

.mobile-preview-link-container {
    height: calc(100dvh - var(--content-dialog-topbar-height));
}

.mobile-preview-link-section-container,
.preview-link-section-container {
    display: flex;
    align-items: start;
}

.preview-link-section-container {
    height: 100%;
}

.mobile-preview-link-section-container {
    min-height: unset;
}

.mobile-preview-link-section,
.preview-link-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.preview-link-section-details {
    border-right: 1px solid #cccccc;
    width: 70%;
    height: 100%;
    padding-top: 10px;
    overflow-y: auto;
}

.mobile-preview-link-section-details {
    width: 100%;
    padding: 20px;
    gap: 25px;
    padding-bottom: var(--page-bottom-padding);
}

.preview-link-section-mobile-preview {
    width: 30%;
    height: 100%;
    padding-inline: 20px;
    display: flex;
    align-items: center;
}

.mobile-preview-link-section-link-details-container,
.preview-link-section-link-details-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 10px;
}

.preview-link-section-link-details-container {
    position: relative;
}

.preview-link-section-link-details-container.short {
    max-width: 55vw;
    padding-inline: 2.5vw;
}

.preview-link-section-link-details-container::after,
.preview-link-section-link-details-container.short::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #cccccc;
    position: absolute;
    bottom: -10px;
    left: 0;
    z-index: 100;
}

.preview-link-section-link-details-container::after {
    width: 100%;
}

.preview-link-section-link-details-container.short::after {
    width: calc(55vw - 16px);
}

.preview-link-section-link-graphs,
.mobile-preview-link-section-link-graphs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 10px;
}

.preview-link-section-link-graphs {
    padding-bottom: 5dvh;
}

.mobile-preview-link-section-link-details,
.preview-link-section-link-details {
    width: 100%;
    display: flex;
    gap: 20px;
    padding: 10px 0;
    display: flex;
    align-items: start;
    gap: 10px;
}

.mobile-preview-link-section-link-details {
    padding: 0;
    height: var(--link-view-image-container-height);
}

.mobile-preview-link-section-link-products-list {
    flex-grow: 1;
    width: 100%;
}

.mobile-preview-link-section-url-container,
.preview-link-section-url-container {
    display: flex;
    align-items: center;
    gap: 15px;
}

.mobile-preview-link-section-url-container {
    flex-direction: column;
    gap: 10px;
    align-items: start;
}

.preview-link-section-url {
    font-size: 15px;
}

.preview-link-section-link-details {
    padding-inline: 20px;
}

.preview-link-section-link-image {
    aspect-ratio: 1;
    flex-shrink: 0;
}

.preview-link-section-link-image {
    height: 100%;
}

.mobile-preview-link-section-link-image {
    width: var(--link-view-image-dimensions);
    height: 100%;
    flex-shrink: 0;
}

.preview-short-section-link-description-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.preview-short-section-link-title {
    font-size: 17px;
    font-weight: 500;
    width: 100%;
}

.preview-link-section-link-description {
    height: 100%;
    padding: 13px 0;
    flex-grow: 1;
    font-size: 13px;
    color: hsl(228, 23%, 60%);
}

.preview-short-section-link-description {
    font-size: 14px;
    color: black;
    max-width: 100%;
}

.preview-link-section-link-analytics {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    width: 100%;
}

.mobile-preview-link-section-link-analytics-data,
.preview-link-section-link-analytics-data {
    display: grid;
    gap: 20px;
    width: 100%;
}

.mobile-preview-link-section-link-analytics-data {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.preview-link-section-link-analytics-data {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.mobile-preview-link-section-buttons-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}

.mobile-preview-link-section-button-image {
    width: 15px;
    height: 15px;
}

.mobile-preview-link-section-button-text {
    font-size: 13px;
    flex-grow: 1;
    text-align: start;
}

.mobile-preview-link-section-button {
    width: 100px;
    padding: 7px 10px;
    border: 1.5px solid hsl(228, 23%, 60%);
    border-radius: 4px;
    color: hsl(231, 9%, 22%);
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.mobile-preview-link-section-button:active {
    background-color: hsl(0, 0%, 93%);
}

.preview-link-section-promocode,
.mobile-preview-link-section-promocode {
    display: flex;
    align-items: center;
    gap: 5px;
}

.preview-link-section-promocode {
    padding-inline: 20px;
}

.preview-link-section-promocode-value {
    font-weight: 500;
    text-transform: uppercase;
    background-color: #c6c5c5;
    font-size: 14px;
    border-radius: 5px;
    padding: 3px;
}