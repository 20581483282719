.mobile-tabs-container,
.tabs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
    position: relative;
    z-index: 60;
    background-color: white;
}

.mobile-tabs-container {
    gap: 10px;
    font-size: 3vw;
    width: 100%;
}

.tabs-container,
.mobile-tabs-container {
    border-top: 1px solid #B7BCD2;
}

.tabs-container.no-top-line,
.mobile-tabs-container.no-top-line {
    border-top-width: 0;
}

.tabs-container::before {
    width: 100%;
}

.mobile-tabs-container::before {
    width: 100vw;
}