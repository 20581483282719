.settings-page-container {
    padding-top: 50px;
}

.mobile-settings-page-container {
    padding: 55px 10px 0 10px;
}

.settings-page-title {
    text-align: start;
    margin-bottom: 20px;
}

.settings-page-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.settings-page-input-subtitle {
    color: var(--subsection-title-color);
    font-size: 15px;
    font-weight: 300;
}

.settings-page-input-title {
    color: var(--section-title-color);
    font-size: 15px;
    font-weight: 500;
}

.settings-page-subtitle-input-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}

.settings-page-subtitle-input-titles-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.settings-page-input-title-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.settings-page-auto-swap-subsection {
    white-space: pre-line;
}

.settings-page-auto-swap-section-try-for-free-button {
    width: 160px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    padding: 13px 15px;
    border-radius: 30px;
    background-color: black;
    color: white;
}