.dashboard-data-value-breakdown-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 30px;
}

.dashboard-data-value-breakdown-value {
    font-size: 30px;
    font-weight: 300;
}

.dashboard-data-value-breakdown-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.dashboard-data-value-breakdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    position: relative;
}

.dashboard-data-value-breakdown-item:not(:last-child)::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: #cccccc;
    position: absolute;
    bottom: -5px;
    left: 0;
    z-index: 90;
}

.dashboard-data-value-breakdown-item-title-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.dashboard-data-value-breakdown-item-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    flex-shrink: 0;
}

.dashboard-data-value-breakdown-item-title,
.dashboard-data-value-breakdown-item-value {
    font-size: 14px;
}