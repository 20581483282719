.sign-up-temp-landing-page-container {
    height: fit-content;
}

.sign-up-temp-landing-page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    width: 100%;
}

.sign-up-temp-landing-page-titles-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.sign-up-temp-landing-page-titles-container > .sign-up-temp-landing-page-title {
    margin: 0;
}

.sign-up-temp-landing-page-subtitle {
    line-height: 1.8rem;
}

.sign-up-temp-landing-page-fields-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input-field,
.textarea-input-field {
    border: none;
    font-size: 15px;
    background-color: transparent;
    height: 100%;
    border-radius: inherit;
    width: 100%;
    outline: none;
    flex: 1 0;
}

.input-field-container {
    height: 50px;
    padding: 0 10px;
}

.textarea-input-field-container {
    padding: 10px;
}

.textarea-input-field-container,
.input-field-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e2e2e2;
    border-radius: 7px;
}

.input-field-valid-indicator,
.input-field-invalid-indicator {
    width: 100%;
    height: 100%;
}

.input-field-invalid-indicator {
    stroke: red;
}

.input-field-valid-indicator {
    fill: green;
}

.sign-up-temp-landing-page-sign-up-button:active {
    background-color: hsl(198, 93%, 53%);
}

.sign-up-temp-landing-page-sign-up-button {
    width: 100%;
    text-align: center;
    background-color: var(--login-signup-button-background-color);
    color: white;
    padding: 15px 0;
    border-radius: 30px;
    cursor: pointer;
    pointer-events: all;
}

.sign-up-temp-landing-page-sign-up-button.disabled {
    background-color: #cccccc7f;
    color: #cccccc;
    cursor: none;
    pointer-events: none;
}

.sign-up-request-page-already-have-account-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.sign-up-request-page-already-have-account-login-button {
    cursor: pointer;
    text-decoration: underline;
    color: var(--login-signup-button-background-color);
}