.paydin-pie-chart-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.paydin-pie-chart-legend {
    display: flex;
    align-items: center;
    gap: 15px;
}

.paydin-pie-chart-legend-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.paydin-pie-chart-legend-item-color {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
}

.paydin-pie-chart-legend-item-title {
    font-size: 13px;
}

.paydin-pie-chart-pie-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.paydin-pie-chart-no-data-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffffdd;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 70;
}

.paydin-pie-chart-no-data-message,
.mobile-paydin-pie-chart-no-data-message {
    font-weight: 500;
}

.paydin-pie-chart-no-data-message {
    font-size: 21px;
}

.mobile-paydin-pie-chart-no-data-message {
    font-size: 14px;
}