.option-value-mapper-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}

.option-value-mapper-values {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.option-value-mapper-status {
    display: flex;
    align-items: center;
    gap: 10px;
}

.option-value-mapper-status-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.option-value-mapper-status-message {
    font-size: 14px;
}

.option-value-mapper-type-picker {
    display: flex;
    align-items: center;
    gap: 10px;
}

.option-value-mapper-option-type {
    min-width: 65px;
    text-align: center;
    padding: 10px;
    border: 1px solid #d8d6d6;
    background-color: white;
    color: black;
    border-radius: 5px;
    cursor: pointer;
}

.option-value-mapper-option-type.selected {
    min-width: 65px;
    border: 1px solid #171616;
    background-color: #767171;
    color: white;
}

.mobile-option-value-mapper-type-picker-container,
.option-value-mapper-type-picker-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.mobile-option-value-mapper-type-picker-container {
    flex-direction: column-reverse;
    align-items: start;
    gap: 10px;
}

.option-value-mapper-pagination-section {
    width: 100%;
    display: flex;
    justify-content: end;
    position: relative;
}

.option-value-mapper-pagination {
    display: flex;
    align-items: center;
    gap: 20px;
}

.option-value-mapper-pagination-state {
    font-size: 14px;
    color: black;
}

.option-value-mapper-pagination-controls {
    display: flex;
    align-items: center;
    gap: 0px;
}

.option-value-mapper-pagination-control-image {
    width: 30px;
    height: 30px;
    padding: 5px;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid transparent;
}

.option-value-mapper-pagination-previous-page-image {
    rotate: 180deg;
}

.option-value-mapper-pagination-control-image:hover {
    border: 1px solid #c2c2c2;
}

.option-value-mapper-pagination-control-image:active {
    background-color: #c2c2c2;
}

.option-value-mapper-pagination-control-image.disabled {
    cursor: none;
    pointer-events: none;
    color: grey;
}

.option-value-mapper-content-toggle-button {
    color: #36b4f1;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}