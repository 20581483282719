.save-changes-strip {
    width: 100%;
    height: var(--dashboard-topbar-height);
    background-color: #3c486a;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 1310;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    translate: 0 calc(var(--dashboard-topbar-height) * -1);
    transition: translate 300ms ease-out;
}

.save-changes-strip.shown {
    translate: 0 0;
}

.save-changes-strip-content {
    width: calc(100% - var(--dashboard-sidebar-width) - 120px);
    height: 100%;
    margin: 0 auto;
    background-color: transparent;
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.save-changes-strip-title-image {
    width: 25px;
    height: 25px;
}

.save-changes-strip-titles-section,
.save-changes-strip-buttons-section {
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
}

.save-changes-strip-discard-button,
.save-changes-strip-save-button {
    font-size: 16px;
    border-radius: 8px;
    padding: 8px 15px;
    cursor: pointer;
}

.save-changes-strip-discard-button {
    color: black;
    background-color: white;
}

.save-changes-strip-discard-button:active {
    background-color: hsl(0, 0%, 90%);
}

.save-changes-strip-save-button {
    color: white;
    padding: 8px 25px;
    background-color: black;
}

.save-changes-strip-save-button:active {
    background-color: hsl(0, 0%, 20%);
}

.save-changes-strip-save-button.disabled {
    opacity: 0.3;
    cursor: none;
    pointer-events: none;
}

.save-changes-strip-logo-image {
    width: 130px;
    aspect-ratio: 5 / 2.5;
}