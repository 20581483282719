.paydin-image-cropper-container {
    --ratios-option-padding: 10px;
    width: 90vmin;
    position: relative;
    max-width: 600px;
    max-height: 600px;
    aspect-ratio: 1;
}

.paydin-image-cropper-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    max-width: calc(100vw - 30px);
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;
}

.paydin-image-cropper-aspect-ratio-button-container {
    position: relative;
    width: fit-content;
}

.paydin-image-cropper-button {
    background-color: white;
    border: 1px solid black;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 7px;
    cursor: pointer;
}

.paydin-image-cropper-aspect-ratio-option {
    padding: 10px;
    cursor: pointer;
    text-align: center;
}

.paydin-image-cropper-aspect-ratio-option.selected {
    background-color: #cccccc;
}

.reactEasyCrop_CropAreaGrid {
    box-shadow: 0 0 0 9999em #edebeb !important;
}