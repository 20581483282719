.mobile-tab-option-container,
.tab-option-container {
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    color: #7f7d7d;
    position: relative;
    z-index: 110;
    white-space: nowrap;
    overflow: hidden;
}

.tab-option-container {
    padding: 20px 10px;
}

.mobile-tab-option-container {
    padding: 10px;
}

.mobile-tab-option-container.selected,
.tab-option-container.selected {
    border-top: 2px solid black;
    color: black;
}

.mobile-tab-option-container.selected.selected-bottom-line,
.tab-option-container.selected.selected-bottom-line {
    border-top: 2px solid transparent;
    border-bottom: 2px solid black;
}