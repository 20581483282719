.mobile-short-view-container,
.short-view-container {
    display: flex;
    flex-direction: column;
}

.short-view-container {
    height: 100%;
    width: 100%;
}

.mobile-short-view-container {
    gap: 0;
    height: 100dvh;
}

.short-view-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: var(--content-dialog-topbar-height);
    padding-bottom: var(--page-bottom-padding);
    overflow: auto;
}

.mobile-short-view-content {
    padding: 65px 0 var(--page-bottom-padding) 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 50px;
    overflow-y: auto;
}

.short-view-more-options-button {
    display: block;
    width: 45px;
    height: 45px;
    padding: 11px;
    margin-left: auto;
    cursor: pointer;
}

.short-view-options-menu-delete-option {
    color: red;
}