.mobile-simulator-product-container {
    --mobile-simulator-product-image-height: 100px;
    --mobile-simulator-product-button-background-color: hsl(0, 0%, 95%);

    position: relative;
}

.mobile-simulator-product-container:before,
.mobile-simulator-product-container:last-child:after {
    content: '';
    width: 100%;
    background-color: #dcdee7;
    position: absolute;
    top: 0;
    left: 0;
}

.mobile-simulator-product-content {
    display: flex;
    align-items: start;
    gap: 10px;
    padding: 10px;
    height: calc(var(--mobile-simulator-product-image-height) + 20px);
}

.mobile-simulator-product-image {
    height: var(--mobile-simulator-product-image-height);
    aspect-ratio: 1 / 1.3;
    flex-shrink: 0;
    object-fit: contain;
    border-radius: 6px;
}

.mobile-simulator-product-details-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mobile-simulator-product-details-upper-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.mobile-simulator-product-title-price-container {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 7px;
}

.mobile-simulator-product-title{
    text-align: start;
    font-size: 14px;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    white-space: break-spaces;
    -webkit-box-orient: vertical;
}

.mobile-simulator-product-add-to-cart-button {
    padding: 10px 7px;
    border-radius: 5px;
    font-size: 12px;
    align-self: end;
    background-color: var(--mobile-simulator-product-button-background-color);
}

.mobile-simulator-product-add-to-cart-more-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}