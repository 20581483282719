.multilink-experiences-table-header-view {
    margin: auto;
    padding: 8px;
    font-size: 14px;
}

.multilink-experiences-table-header-view.link-type {
    min-width: 95px;
}

.multilink-experiences-table-header-view.name {
    min-width: 70px;
}