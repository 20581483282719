.layout-topbar-right-section {
    display: flex;
    align-items: center;
    gap: 20px;
}

.dashboard-layout-container .layout-topbar-right-section {
    margin-left: auto;
}

.dashboard-topbar-username-image,
.dashboard-topbar-username-circle {
    cursor: pointer;
    color: white;
    text-transform: uppercase;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.dashboard-topbar-username-circle {
    background-color: var(--dashboard-topbar-initials-circle-background-color);
}

.dashboard-topbar-username-initials {
    font-size: 19px;
    width: fit-content;
}

.welcome-topbar-right-buttons-container {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: center;
}

.welcome-topbar-right-button {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.dashboard-topbar-user-menu-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 25px;
    color: var(--dashboard-user-menu-text-color);
}

.dashboard-topbar-user-menu-titles-container {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.dashboard-topbar-user-menu-username-initials-circle {
    background-color: var(--dashboard-topbar-initials-circle-background-color);
    color: white;
    text-transform: uppercase;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.dashboard-topbar-user-menu-names-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 5px;
    font-size: 14px;
    width: 100%;
}

.dashboard-topbar-user-menu-username {
    color: black;
    font-size: 15px;
    font-weight: 500;
}

.dashboard-topbar-user-menu-name {
    font-size: 12px;
}

.dashboard-topbar-user-menu-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    width: 100%;
}

.dashboard-topbar-user-menu-section-title {
    font-size: 16px;
    font-weight: 500;
    padding-inline-start: 8px;
}

.dashboard-topbar-user-menu-section-content {
    padding-inline: 8px;
    width: 100%;
    font-size: 14px;
}

.dashboard-topbar-user-menu-plan-section-plan-name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-topbar-user-menu-plan-section-plan-upgrade-button {
    padding: 7px 14px;
    border: 1.5px solid black;
    border-radius: 10px;
    background-color: white;
    color: black;
    cursor: pointer;
}

.dashboard-topbar-user-menu-plan-section-plan-upgrade-button:hover {
    background-color: hsl(0, 0%, 95%);
}

.dashboard-topbar-user-menu-plan-section-plan-upgrade-button:active {
    background-color: hsl(0, 0%, 90%);
}

.dashboard-topbar-influencer-menu-store-section-store-provider-image-container,
.dashboard-topbar-user-menu-store-section-store-provider-image-container {
    border-radius: 10px;
    background-color: white;
}

.dashboard-topbar-user-menu-store-section-store-provider-image-container {
    width: 35px;
    height: 35px;
    border: 1.5px solid #cccccc;
}

.dashboard-topbar-influencer-menu-store-section-store-provider-image-container {
    width: 100px;
    aspect-ratio: 2;
}

.dashboard-topbar-user-menu-store-section-store-provider-image {
    padding: 3px;
}

.dashboard-topbar-user-menu-store-section-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.dashboard-topbar-user-menu-store-section-shopify-provider-container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
}

.dashboard-topbar-user-menu-store-section-store-provider-name {
    font-size: 18px;
}

.dashboard-topbar-user-menu-account-section-content {
    width: 100%;
    padding: 0;
}

.dashboard-topbar-user-menu-option-image {
    width: 17px;
    height: 17px;
}

.dashboard-topbar-user-menu-support-section-option-terms-and-conditions-image > path {
    stroke: var(--dashboard-user-menu-text-color);
}

.dashboard-topbar-user-menu-support-section-content {
    width: 100%;
    padding: 0;
}

.dashboard-topbar-user-menu-option-container {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    padding: 12px 8px;
    border-radius: 15px;
    color: var(--dashboard-user-menu-text-color);
}

.dashboard-topbar-user-menu-option-text {
    text-transform: capitalize;
}

.dashboard-topbar-user-menu-option-container.disabled {
    cursor: none;
    pointer-events: none;
    opacity: 0.5;
}

.dashboard-topbar-user-menu-option-container:hover {
    background-color: hsl(0, 0%, 96%);
}

a.dashboard-topbar-user-menu-option-container:hover {
    background-color: hsl(0, 0%, 96%);
    color: var(--dashboard-user-menu-text-color);
}

.dashboard-topbar-user-menu-option-container:active {
    background-color: hsl(0, 0%, 92%);
}

a.dashboard-topbar-user-menu-option-container:active {
    background-color: hsl(0, 0%, 92%);
    color: var(--dashboard-user-menu-text-color);
}

.mobile-dashboard-topbar-more-options-drawer-text-container,
.mobile-dashboard-topbar-more-options-drawer-image-container {
    display: flex;
}

.mobile-dashboard-topbar-more-options-drawer-text-container {
    width: 62%;
}

.mobile-dashboard-topbar-more-options-drawer-image-container {
    width: 38%;
}

.mobile-dashboard-topbar-more-options-drawer-image {
    width: 15px;
    height: 15px;
    margin-inline-start: auto;
}

.mobile-dashboard-topbar-more-options-drawer-text {
    padding-inline-start: 15px;
    text-align: start;
    text-transform: capitalize;
}

.mobile-dashboard-topbar-more-options-drawer-option {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    position: relative;
}

.mobile-dashboard-topbar-more-options-drawer-option:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.mobile-dashboard-topbar-more-options-drawer-option::after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: #cccccc;
    width: 100%;
    content: "";
}

.mobile-dashboard-topbar-more-options-drawer-option:active {
    background-color: hsl(0, 0%, 90%);
}

.mobile-dashboard-topbar-more-options-drawer-option.disabled {
    color: hsl(69, 7%, 80%);
    cursor: none;
    pointer-events: none;
}

.mobile-dashboard-topbar-more-options-drawer-option-logout {
    color: red;
}

.layout-topbar-right-section-menu-image {
    width: 20px;
    height: 20px;
}