.mobile-preview-multilink-container,
.preview-multilink-container {
    --multilink-view-main-frame-min-height: 590px;

    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    height: calc(var(--content-dialog-height) - var(--content-dialog-topbar-height));
    margin-top: var(--content-dialog-topbar-height);
    overflow-y: auto;
}

.mobile-preview-multilink-container {
    height: calc(100dvh - var(--content-dialog-topbar-height));
}

.mobile-preview-multilink-section-container,
.preview-multilink-section-container {
    display: flex;
    align-items: start;
}

.preview-multilink-section-container {
    height: 100%;
}

.mobile-preview-multilink-section-container {
    min-height: unset;
}

.mobile-preview-multilink-section,
.preview-multilink-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.preview-multilink-section-details {
    border-right: 1px solid #cccccc;
    width: 70%;
    height: 100%;
    padding-top: 10px;
    overflow-y: auto;
}

.mobile-preview-multilink-section-details {
    width: 100%;
    padding: 20px;
    gap: 25px;
    padding-bottom: var(--page-bottom-padding);
}

.mobile-preview-multilink-section-multilink-details-container,
.preview-multilink-section-multilink-details-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 10px;
}

.preview-multilink-section-multilink-details-container {
    position: relative;
    max-width: 65vw;
    padding-inline: 2.5vw;
}

.preview-multilink-section-multilink-details-container::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #cccccc;
    position: absolute;
    bottom: -10px;
    left: 0;
    z-index: 100;
    width: calc(65vw - 16px);
}

.preview-multilink-section-multilink-graphs,
.mobile-preview-multilink-section-multilink-graphs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 10px;
}

.preview-multilink-section-multilink-graphs {
    padding-bottom: 5dvh;
}

.mobile-preview-multilink-section-multilink-details,
.preview-multilink-section-multilink-details {
    width: 100%;
    display: flex;
    gap: 20px;
    padding: 10px 0;
    display: flex;
    align-items: start;
    gap: 10px;
}

.mobile-preview-multilink-section-multilink-details {
    padding: 0;
    height: var(--multilink-view-image-container-height);
}

.preview-multilink-section-multilink-details {
    padding-inline: 20px;
}

.preview-multilink-section-multilink-description-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.preview-multilink-section-multilink-description {
    font-size: 14px;
    color: black;
    max-width: 100%;
}

.preview-multilink-section-multilink-analytics {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    width: 100%;
}

.mobile-preview-multilink-section-multilink-analytics-data,
.preview-multilink-section-multilink-analytics-data {
    display: grid;
    gap: 20px;
    width: 100%;
}

.mobile-preview-multilink-section-multilink-analytics-data {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.preview-multilink-section-multilink-analytics-data {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.preview-multilink-section-promocode,
.mobile-preview-multilink-section-promocode {
    display: flex;
    align-items: center;
    gap: 5px;
}

.preview-multilink-section-promocode {
    padding-inline: 20px;
}

.preview-multilink-section-promocode-value {
    font-weight: 500;
    text-transform: uppercase;
    background-color: #c6c5c5;
    font-size: 14px;
    border-radius: 5px;
    padding: 3px;
}