.mobile-influencer-view-container,
.influencer-view-container {
    display: flex;
    flex-direction: column;
}

.influencer-view-container {
    gap: 15px;
    width: 100%;
    height: var(--content-dialog-height);
}

.mobile-influencer-view-container {
    gap: 0;
    width: 100%;
}

.influencer-view-content {
    padding-bottom: var(--page-bottom-padding);
    margin-top: var(--content-dialog-topbar-height);
    padding: 0 30px var(--page-bottom-padding) 30px;
    overflow: auto;
}

.mobile-influencer-view-content {
    padding-top: 45px;
    height: 100dvh;
}