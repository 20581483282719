.mobile-edit-influencer-section,
.edit-influencer-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 25px;
    padding: 15px 15px 30px 15px;
}

.mobile-edit-influencer-section {
    height: calc(100dvh - var(--content-dialog-topbar-height));
    overflow-y: auto;
    padding-bottom: 20dvh;
}

.mobile-edit-influencer-section-image-frame,
.edit-influencer-section-image-frame {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.edit-influencer-section-image-frame {
    width: 160px;
}

.mobile-edit-influencer-section-image-frame {
    width: 120px;
}

.edit-influencer-section-influencer-permissions-title,
.edit-influencer-section-image-frame-title {
    font-size: 14px;
    color: #7f859e;
}

.edit-influencer-section-image-container {
    position: relative;
    border-radius: 50%;
}

.edit-influencer-section-image-container.required {
    border: 2px solid red;
}

.edit-influencer-section-image-container-error-message {
    font-size: 14px;
    color: red;
}

.mobile-edit-influencer-section-image,
.edit-influencer-section-image {
    aspect-ratio: 1;
}

.edit-influencer-section-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.mobile-edit-influencer-section-button,
.edit-influencer-section-button {
    align-self: end;
    color: white;
    background-color: black;
    text-transform: uppercase;
    font-size: 17px;
    text-align: center;
    padding: 12px 0;
    cursor: pointer;
    pointer-events: all;
    margin-top: 30px;
}

.edit-influencer-section-button {
    width: 50%;
}

.mobile-edit-influencer-section-button {
    width: 100%;
}

.edit-influencer-section-button.disabled,
.mobile-edit-influencer-section-button.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: none;
}

.edit-influencer-section-add-media-button,
.edit-influencer-section-add-media-button.has-image {
    cursor: pointer;
}

.mobile-edit-influencer-section-add-media-button,
.edit-influencer-section-add-media-button {
    display: block;
    border-radius: 50%;
    aspect-ratio: 1;
    border: 1px solid var(--add-media-container-color);
}

.mobile-edit-influencer-section-add-media-button.has-image,
.edit-influencer-section-add-media-button.has-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    display: none;
}

.edit-influencer-section-add-media-button-inner-container {
    width: 95%;
    height: 95%;
    border: 1px dotted var(--add-media-container-color);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2.5%;
    color: var(--add-media-container-color);
}

.edit-influencer-section-add-media-button-plus {
    font-size: 35px;
    font-weight: 200;
}

.mobile-edit-influencer-section-add-media-button-plus {
    font-size: 25px;
    font-weight: 200;
}

.edit-influencer-section-image-container:hover > .edit-influencer-section-image {
    opacity: 0.5;
}

.edit-influencer-section-image-container:hover > .edit-influencer-section-add-media-button {
    display: block;
}

.edit-influencer-section-influencer-commission-calculation-sections-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.edit-influencer-section-influencer-commission-calculation-section {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-inline-start: 5px;
    cursor: pointer;
}

.edit-influencer-section-influencer-permissions {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
}

.edit-influencer-section-influencer-permissions-sections-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.edit-influencer-section-influencer-permissions-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edit-influencer-section-influencer-permissions-section {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-inline-start: 5px;
    cursor: pointer;
}

.edit-influencer-section-influencer-permissions-section.sub-permission {
    margin-inline-start: 10px;
}


.edit-influencer-switch {
    margin: 10px 0;
}

.edit-influencer-switch-label {
    font-size: 15px;
    color: #818080;
}