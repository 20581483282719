.multilink-item-container,
.mobile-multilink-item-container {
    --image-width: 45px;
    --image-width-height-ratio: 1;
    --image-height: calc(var(--image-width) * var(--image-width-height-ratio));
    --multilink-item-details-parent-gap: 15px;
    
    padding: 10px;
    border: 1px solid hsl(200, 27%, 85%);
    border-radius: 4px;
    position: relative;
    background-color: white;
    cursor: pointer;
    transition: box-shadow 200ms ease-out;
}

.multilink-item-container:hover {
    box-shadow: 0 0 5px 3px #cccccc;
}

.multilink-item-image {
    width: var(--image-width);
    aspect-ratio: 1 / var(--image-width-height-ratio);
    flex-shrink: 0;
    cursor: pointer;
    height: 100%;
    margin-left: 5px;
    fill: #808080;
}

.multilink-item-content {
    width: 100%;
    height: 100%;
}

.multilink-item-details-container,
.mobile-multilink-item-details-container {
    width: 100%;
}

.mobile-multilink-item-details-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.multilink-item-details-container {
    display: flex;
    align-items: center;
    gap: var(--multilink-item-details-parent-gap);
}

.multilink-item-image {
    width: var(--image-width);
    aspect-ratio: 1 / var(--image-width-height-ratio);
    flex-shrink: 0;
    cursor: pointer;
}

.multilink-item-details {
    flex: 1 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 5px;
    width: calc(100% - (var(--image-width) + var(--multilink-item-details-parent-gap)));
    height: var(--image-height);
}

.multilink-item-created-at-expired-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    position: relative;
}

.multilink-item-titles-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    padding-inline-end: 85px;
}

.multilink-item-details-lower-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.multilink-item-title,
.mobile-multilink-item-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.multilink-item-title {
    font-size: 14px;
}

.mobile-multilink-item-title {
    font-size: 13px;
}

.multilink-item-statistics {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.multilink-item-created-at-container {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 70%;
    gap: 10px;
}

.multilink-item-statistics-container {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
}

.multilink-item-detail-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.multilink-item-statistics-views-container {
    width: 80%;
}

.multilink-item-statistics-image {
    aspect-ratio: 1;
}

.multilink-item-statistics-created-at-image {
    width: 11px;
}

.multilink-item-statistics-links-number-image {
    width: 12px;
}

.mobile-multilink-item-statistics-value-suffix,
.multilink-item-statistics-value-suffix,
.multilink-item-statistics-value {
    font-size: 15px;
    font-weight: 300;
}

.multilink-item-statistics-value {
    font-size: 15px;
}

.multilink-item-statistics-created-at-value.multilink-item-statistics-value {
    font-size: 12px;
}

.mobile-multilink-item-statistics-value {
    font-size: 12px;
    font-weight: 300;
}

.multilink-item-buttons {
    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 70;
    display: flex;
    align-items: center;
    gap: 5px;
}

.multilink-item-copy-to-clipboard-button {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #f2f1f1;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
}

.multilink-item-copy-to-clipboard-button-image {
    width: 12px;
    height: 12px;
}

.multilink-item-copy-to-clipboard-button-text {
    font-size: 12px;
}

.multilink-item-statistics-more-options-button,
.mobile-multilink-item-statistics-more-options-button {
    width: 25px;
    height: 25px;
    cursor: pointer;
    border: 1px solid #e8e8e8;
}

.multilink-item-statistics-more-options-button {
    padding: 5px;
}

.mobile-multilink-item-statistics-more-options-button {
    padding: 5px;
}

.mobile-dashboard-topbar-more-options-button-username-initials-cirlce {
    background-color: #616c6e;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: relative;
}

.mobile-multilink-item-more-options-menu-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mobile-multilink-item-more-options-menu-single-item {
    padding: 14px;
    font-size: 15px;
    background-color: var(--link-item-more-options-menu-background-color);
    border-radius: var(--link-item-more-options-menu-border-radius);
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.mobile-multilink-item-more-options-menu-item-line {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.mobile-multilink-item-more-options-menu-section-item-image,
.mobile-multilink-item-more-options-menu-single-item-image {
    width: 20px;
    height: 20px;
}

.mobile-multilink-item-more-options-menu-single-item:active {
    background-color: hsl(0, 0%, 85%);
}

.mobile-multilink-item-more-options-menu-section-item-delete-multilink {
    color: red;
}

.multilink-item-options-menu-delete-option {
    color: red;
}