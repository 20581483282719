.mobile-policies-footer-text,
.policies-footer-text {
    text-align: center;
    font-size: 12px;
    width: 100%;
    padding-bottom: 20px;
}

.policies-footer-text-link {
    text-decoration: underline;
}

.policies-footer-text-link,
.policies-footer-text-link:visited {
    color: black;
}

.mobile-policies-footer-text-space-block {
    height: 3rem;
}

.policies-footer-text-space-block {
    height: 5rem;
}