.product-table-row-view  {
    margin: auto;
}

.product-table-row-view-product {
    display: flex;
    align-items: center;
    gap: 20px;
}

.product-table-row-view-image {
    width: 80px;
    aspect-ratio: 1;
    flex-shrink: 0;
}

.product-table-row-view-view-count,
.product-table-row-view-add-count,
.product-table-row-view-buy-count {
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
}

.product-table-row-view-title {
    font-size: 14px;
    text-align: start;
}

.mobile-product-table-row-view-ranking,
.product-table-row-view-ranking {
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-table-row-view-ranking {
    width: 70px;
    height: 70px;
    font-size: 25px;
}

.mobile-product-table-row-rank-container {
    width: 25%;
}

.mobile-product-table-row-view-ranking {
    width: 50px;
    height: 50px;
    font-size: 15px;
}

.mobile-product-table-row-view {
    display: flex;
    align-items: center;
    gap: 10px;
}

.mobile-product-table-row-content {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 75%;
}

.mobile-product-table-row-view-image {
    width: 60px;
    aspect-ratio: 1 / 1.3;
    flex-shrink: 0;
}

.mobile-product-table-row-content-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    gap: 10px;
}

.mobile-product-table-row-view-title {
    font-size: 12px;
    text-align: start;
}

.mobile-product-table-row-content-details-analytics {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.mobile-product-table-row-content-details-analytics-data {
    display: flex;
    align-items: center;
    gap: 3px;
}

.mobile-product-table-row-content-details-analytics-data-value,
.mobile-product-table-row-content-details-analytics-data-label {
    font-size: 12px;
    color: black;
    font-weight: 500;
}