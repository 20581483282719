.check-your-email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 90%;
    max-width: 350px;
    margin-inline: auto;
    margin-top: 15%;
}

.check-your-email-section {
    border: 1px solid #c8c8c8;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
}

.check-your-email-image {
    width: 75px;
    height: 75px;
}

.check-your-email-message {
    font-size: 19px;
    text-align: center;
    line-height: 1.5;
    white-space: pre-line;
}

.check-your-email-go-back-button {
    color: var(--login-signup-button-background-color);
    text-decoration: underline;
    cursor: pointer;
}