.mobile-shopboard-page-container,
.shopboard-page-container {
    height: 100dvh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.mobile-shopboard-page-container {
    padding-top: var(--content-dialog-topbar-height);
}

.shopboard-page-container::-webkit-scrollbar {
    display: none;
}

.shopboard-page-top-section {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding-top: 70px;
    gap: 20px;
}

.shopboard-page-title-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.shopboard-page-title {
    font-size: 24px;
    font-weight: 200;
    color: #7e7e7e;
}

.shopboard-page-content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10dvh;
}

.shopboard-page-content-body {
    width: 100%;
    flex-grow: 1;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 5px 2px #f1f0f0;
    padding: 20px 30px;
}

.shopboard-page-content-no-connections,
.mobile-shopboard-page-content-no-connections {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
}

.mobile-shopboard-page-content-no-connections {
    height: 100%;
    padding-inline: 20px;
    gap: 15px;
}

.shopboard-page-content-connected {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 25px;
    position: relative;
}

.mobile-shopboard-page-content-no-connections-titles,
.shopboard-page-content-no-connections-titles {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mobile-shopboard-page-content-no-connections-titles {
    padding-top: 20px;
}

.shopboard-page-content-no-connections-title {
    font-size: 30px;
    font-weight: 300;
}

.shopboard-page-content-no-connections-subtitle {
    font-size: 16px;
    font-weight: 300;
    width: 50%;
}

.shopboard-page-content-no-connections-image-container {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    align-items: start;
}

.shopboard-page-content-no-connections-image {
    width: 100%;
    height: 300px;
    flex-shrink: 0;
    display: flex;
    align-items: start;
}

.shopboard-page-content-image {
    height: 100%;
    width: 100%;
}

.shopboard-page-link-url-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 8px;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    background-color: white;
    cursor: pointer;
}

.shopboard-page-link-url-field-text {
    color: #9a9a9a;
    font-size: 14px;
    font-weight: 300;
}

.shopboard-page-link-url-field-text:hover {
    color: #9a9a9a;
}

.shopboard-page-link-url-field-image {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    color: #9a9a9a;
}

.shopboard-page-analytics {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 15px;
}

.shopboard-page-content-connected-tab-content-syncing {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.shopboard-page-content-connected-tab-content-syncing-titles-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.shopboard-page-content-connected-tab-content-syncing-title {
    font-size: 23px;
    font-weight: 200;
    color: grey;
}

.shopboard-page-content-connected-tab-content-syncing-subtitle {
    font-size: 15px;
    font-weight: 200;
    color: grey;
}

.shopboard-page-content-connected-tab-content {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
}

.shopboard-page-content-connected-tab-content-connected {
    height: 100%;
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shopboard-page-content-no-connections-connect-buttons {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    margin-top: 100px;
    flex-shrink: 0;
}

.mobile-shopboard-page-content-connected-tab-content-list-container,
.shopboard-page-content-connected-tab-content-list-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 240px));
    justify-content: center;
    gap: 30px;
    padding: 10px;
}

.mobile-shopboard-page-content-connected-tab-content-list-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
    padding: 10px 5px 0 5px;
    width: 100%;
    padding-bottom: 5dvh;
}

.mobile-shopboard-page-content {
    height: 100%;
}

.mobile-shopboard-page-content-no-connections-title {
    font-size: 26px;
}

.mobile-shopboard-page-content-no-connections-subtitle {
    font-size: 14px;
    font-weight: 300;
}

.mobile-shopboard-page-content-no-connections-socials {
    width: 80%;
}

.mobile-shopboard-page-content-connected-tab-content {
    height: 100dvh;
    padding-top: 70px;
    padding-inline: 5px;
    overflow-y: auto;
}

.mobile-shopboard-page-content-connected-tab-content.no-list {
    display: flex;
    justify-content: center;
    align-items: center;
}

.shopboard-page-loader-row,
.mobile-shopboard-page-loader-row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shopboard-page-loader-row {
    height: 90px;
    margin-bottom: 30px;
}

.mobile-shopboard-page-loader-row {
    height: 50px;
    margin-bottom: 15px;
}

.mobile-shopboard-page-content-no-connections-image {
    height: 100%;
}

.mobile-shopboard-page-more-options-button {
    display: block;
    width: 45px;
    height: 45px;
    padding: 11px;
    margin-left: auto;
    cursor: pointer;
}