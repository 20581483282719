.image-box-container {
    position: relative;
}

.image-box-loader-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    z-index: 30;
}

.image-box-image {
    object-fit: contain;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    aspect-ratio: inherit;
    border-radius: 10px;
}

.image-box-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 50;
    inset: 0;
    background: linear-gradient(to top, #50505078, transparent);
}