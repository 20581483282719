.multi-line-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: break-spaces;
    -webkit-box-orient: vertical;
    max-width: calc(100% - 15px);
}

.one-line-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.multi-line-text.one-word {
    word-wrap: break-word;
    white-space: initial;
}