.mobile-time-select-container,
.time-select-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    cursor: pointer;
}

.mobile-time-select-container {
    width: 100%;
}

.mobile-time-select,
.time-select {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid hsl(229, 23%, 72%);
    border-radius: 5px;
    position: relative;
    color: hsl(229, 23%, 72%);
    padding: 5px 5px 5px 10px;
    cursor: pointer;
}

.time-select {
    width: fit-content;
}

.mobile-time-select {
    min-width: 40%;
    width: fit-content;
}

.time-select-image {
    width: 20px;
    height: 20px;
}

.mobile-time-select-time-desc {
    font-size: 14px;
    font-weight: 500;
}

.time-select-display {
    width: calc(100% - 20px);
    height: 20px;
    color: hsl(229, 23%, 72%);
}