.selected-url-selection-type,
.url-selection-type {
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid #e1e1e1;
    background-color: white;
    padding: 10px;
}

.url-selection-type.selected,
.selected-url-selection-type {
    position: relative;
    border-color: green;
    border-style: solid;
}

.selected-url-selection-type-image {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 80;
}

.mobile-selected-url-selection-type-title,
.selected-url-selection-type-title {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 5px;
    z-index: 90;
}

.selected-url-selection-type-title {
    font-size: 14px;
}

.mobile-selected-url-selection-type-title {
    font-size: 12px;
}

.url-selection-type-image {
    width: 70px;
    height: 70px;
}

.mobile-url-selection-type-title,
.url-selection-type-title {
    font-weight: 300;
    color: black;
    text-align: center;
}

.url-selection-type-title {
    font-size: 15px;
}

.mobile-url-selection-type-title {
    font-size: 12px;
}