.mobile-edit-short-section-container,
.edit-short-section-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0;
}

.edit-short-section-container {
    padding-top: 20px;
}

.edit-short-section-title {
    font-size: 18px;
    font-weight: 500;
}

.edit-short-section-url-input-title {
    font-size: 17px;
    color: #7f859e;
    padding-inline-start: 10px;
}

.edit-short-section-url-input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.edit-short-section-url-input {
    width: 100%;
    outline: none;
    border: 1px solid black;
    border-radius: 25px;
    padding: 10px 15px;
}

.edit-short-section-url-input::placeholder {
    color: #7f859e;
}

.edit-short-section-button,
.mobile-edit-short-section-button {
    align-self: end;
    color: white;
    background-color: black;
    text-transform: uppercase;
    font-size: 17px;
    text-align: center;
    padding: 12px 0;
    cursor: pointer;
    pointer-events: all;
    margin-top: 30px;
}

.edit-short-section-button {
    width: 50%;
}

.mobile-edit-short-section-button {
    width: 100%;
}

.edit-short-section-button.disabled,
.mobile-edit-short-section-button.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: none;
}

.edit-short-section-website-iframe {
    width: 100%;
    height: 100%;
}

.edit-short-section-url-preview-container {
    width: 100%;
    height: 25dvh;
    position: relative;
}

.edit-short-section-url-selection-types {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-inline: 20px;
}

.edit-short-section-url-selection-type-image {
    color: #a7a7a7;
}

.edit-short-section-url-selection-collection-type-image path {
    fill: #a7a7a7;
}

.mobile-edit-short-section-title-container,
.edit-short-section-title-container,
.mobile-edit-short-section-url-container,
.edit-short-section-url-container,
.mobile-edit-short-section-promocode-container,
.edit-short-section-promocode-container {
    padding: 15px 20px;
    border-bottom: 1px solid #cccccc;
    width: 100%;
}

.mobile-edit-short-section-influencer-select-container,
.edit-short-section-influencer-select-container {
    border-bottom: 1px solid #cccccc;
    width: 100%;
}

.mobile-edit-short-section-utm-container,
.edit-short-section-utm-container {
    padding: 15px 0;
    width: 100%;
}

.edit-short-section-utm-container {
    padding: 15px 0;
}

.edit-short-section-alert-message-container {
    padding: 0 30px 20px 30px;
    width: 100%;
}