.color-select-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.color-select-item-frame {
    padding: 4px 6px;
}

.color-select-item {
    border-radius: 50%;
}

.color-select-item.selected {
    position: relative;
}

.color-select-item-selected-v-mark {
    position: absolute;
    inset: 0;
    margin: auto;
    color: white;
}

.color-select-item.out-of-stock {
    position: relative;
    pointer-events: none;
    cursor: none;
}

.color-select-item.out-of-stock::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 100%;
    height: 1px;
    background-color: #4e4d4d;
    rotate: -45deg;
}

.color-select-item-more-colors {
    font-size: 13px;
    text-decoration: underline;
    padding-inline-start: 5px;
}

.color-select-menu-item {
    display: flex;
    gap: 10px;
    align-items: center;
}