.dashboard-topbar-container,
.mobile-dashboard-topbar-container {
    --mobile-dashboard-topbar-right-section-button-height: 60px;

    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 300;
}

.mobile-dashboard-topbar-paylink-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    height: var(--mobile-dashboard-topbar-titles-section);
    padding: 0px 15px;
}

.mobile-dashboard-topbar-paylink-title-container.free {
    background-color: #4372c4;
    color: white
}

.mobile-dashboard-topbar-content {
    border-bottom: 1px solid #efefef;
}

.mobile-dashboard-topbar-paylink-logo-container {
    display: flex;
    align-items: center;
    gap: 15px;
}

.mobile-dashboard-topbar-paylink-logo {
    width: 35px;
    height: 35px;
}

.mobile-dashboard-topbar-add-link-button {
    width: 25px;
    height: 25px;
}

.mobile-dashboard-topbar-add-influencer-button {
    width: 20px;
    height: 20px;
}

.mobile-dashboard-topbar-right-section {
    display: flex;
    align-items: center;
    position: relative;
    gap: 5px;
}

.mobile-dashboard-topbar-add-options-menu {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 0 5px 3px #cccccc;
    position: absolute;
    top: var(--mobile-dashboard-topbar-right-section-button-height);
    right: 10px;
    z-index: 90;
}

.mobile-dashboard-topbar-add-options-menu-option {
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: inherit;
}

.mobile-dashboard-topbar-add-options-menu-option:active {
    background-color: hsl(0, 0%, 90%);
}

.mobile-dashboard-topbar-add-button {
    padding: 0 6px;
    width: 40px;
    height: var(--mobile-dashboard-topbar-right-section-button-height);
}

.mobile-dashboard-topbar-add-options-menu-option-image {
    width: 15px;
    height: 15px;
}

.dashboard-topbar-content {
    height: var(--dashboard-topbar-height);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.dashboard-topbar-bottom-line {
    background-color: #cccccc;
    height: 1px;
    width: 100%;
}

.mobile-dashboard-topbar-buttons-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 8px 8px 0 8px;
}


.mobile-dashboard-topbar-button-image.disabled > path {
    fill: hsl(69, 7%, 80%);
}

.mobile-dashboard-topbar-button-image,
.mobile-dashboard-topbar-user-circle-button {
    width: 32px;
    height: 32px;
}

.mobile-dashboard-topbar-user-circle-image {
    width: 35px;
    height: 35px;
}

.mobile-dashboard-topbar-more-options-drawer-option-logout {
    color: red;
}

.mobile-dashboard-topbar-add-button-menu {
    width: 145px;
}

.mobile-dashboard-topbar-store-free-trial-container {
    font-size: 13px;
}

.mobile-dashboard-topbar-free-trial-section {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 15px;
}

.mobile-dashboard-topbar-free-trial-icon {
    width: 20px;
    height: 20px;
}

.free-trial-upgrade-button {
    font-weight: bold;
    text-decoration: underline;
}

.mobile-dashboard-topbar-user-circle-button-username-initials-cirlce {
    background-color: #616c6e;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid #616c6e;
}

.mobile-dashboard-topbar-user-circle-button-username-initials-cirlce.free {
    border: 1px ridge white;
}

.mobile-dashboard-topbar-user-circle-button-text {
    width: fit-content;
    height: fit-content;
    text-transform: uppercase;
    color: white;
    font-size: 13px;
    position: absolute;
    inset: 0;
    margin: auto;
}

.mobile-dashboard-topbar-add-options-menu-magic-store-option {
    background-image: linear-gradient(to right, var(--magic-purple-color), #84BFDE, #94f2f7);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.mobile-dashboard-topbar-more-options-drawer-option-image {
    width: 16px;
    height: 16px;
}