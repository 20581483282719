.mobile-links-page-lifetime-analytics-brief,
.links-page-lifetime-analytics-brief {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 100;
    padding: 10px 0;
}

.links-page-lifetime-analytics-brief {
    gap: 60px;
}

.mobile-links-page-lifetime-analytics-brief {
    gap: 30px;
    flex-wrap: wrap;
}

.mobile-links-page-lifetime-analytics-brief::after,
.links-page-lifetime-analytics-brief::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #cccccc;
    width: 100%;
    position: absolute;
    z-index: 90;
    right: 0;
    left: 0;
    bottom: 0;
}

.mobile-links-page-lifetime-analytics-brief-data-container,
.links-page-lifetime-analytics-brief-data-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.mobile-links-page-lifetime-analytics-brief-data,
.links-page-lifetime-analytics-brief-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}

.links-page-lifetime-analytics-brief-data-value,
.links-page-lifetime-analytics-brief-data-title {
    text-transform: lowercase
}

.links-page-lifetime-analytics-brief-data-value {
    font-size: 15px;
    color: black;
}

.links-page-lifetime-analytics-brief-data-title {
    font-size: 15px;
    font-weight: 300;
    color: #373737;
}

.links-page-lifetime-analytics-brief-revenue-dot,
.links-page-lifetime-analytics-brief-clicks-dot,
.links-page-lifetime-analytics-brief-sales-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.links-page-lifetime-analytics-brief-clicks-dot {
    background-color: rgb(73 157 228);
}

.links-page-lifetime-analytics-brief-sales-dot {
    background-color: rgb(120 221 148);
}

.links-page-lifetime-analytics-brief-revenue-dot {
    background-color: #ff7cea;
}