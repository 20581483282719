.mobile-influencer-strip-container,
.influencer-strip-container {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.influencer-strip-container {
    padding-inline: 20px;
}

.mobile-influencer-strip-container {
    padding-inline: 0;
}

.influencer-strip-title {
    font-size: 14px;
    color: #7f859e;
}

.mobile-influencer-strip-content,
.influencer-strip-content {
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    width: fit-content;
    padding-right: 20px;
    border: 1px solid #cccccc;
}

.influencer-strip-image-container {
    width: 50px;
    aspect-ratio: 1;
}

.influencer-strip-image {
    border: 1px solid #cccccc;
}

.influencer-strip-username {
    font-size: 14px;
    color: #3e3e3e;
}