.dashboard-analytics-single-value-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 3px;
}

.dashboard-analytics-single-value-text {
    font-size: 32px;
    font-weight: 300;
}

.mobile-dashboard-analytics-single-value-text {
    font-size: 26px;
    font-weight: 300;
}

.dashboard-analytics-single-value-secondary-text {
    font-size: 14px;
    font-weight: 300;
    color: grey;
}

.mobile-dashboard-analytics-single-value-secondary-text {
    font-size: 11px;
    font-weight: 300;
    color: grey;
}