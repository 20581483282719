.back-button-container {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.back-button-image {
    width: 25px;
    height: 25px;
}

.mobile-back-button-image {
    width: 45px;
    height: 45px;
    padding: 10px;
}

.back-button-text {
    font-weight: 300;
}