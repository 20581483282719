.floating-menu-container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 0 5px 3px #cccccc;
    position: absolute;
    top: var(--mobile-dashboard-topbar-right-section-button-height);
    right: 10px;
    z-index: 90;
}

.floating-menu-option {
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: black;
}

.floating-menu-option:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.floating-menu-option:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.floating-menu-option:active {
    background-color: hsl(0, 0%, 90%);
}

.floating-menu-option-image {
    width: 15px;
    height: 15px;
}