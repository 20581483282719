.mobile-multi-select-status-button,
.multi-select-status-button {
    width: 100%;
    display: flex;
    gap: 10px;
    cursor: pointer;
    background-color: white;
    padding: 20px 8px;
    justify-content: space-between;
}

.multi-select-status-button {
    padding: 20px;
}

.mobile-multi-select-status-button {
    padding: 0;
}

.mobile-multi-select-status-button-title,
.multi-select-status-button-title {
    color: hsl(231, 17%, 39%);
    font-weight: 500;
    flex-shrink: 0;
}

.multi-select-status-button-title {
    font-size: 16px;
}

.mobile-multi-select-status-button-title {
    font-size: 15px;
}

.mobile-multi-select-status-button-right-section,
.multi-select-status-button-right-section {
    display: flex;
    align-items: center;
}

.multi-select-status-button-right-section {
    gap: 5px;
}

.mobile-multi-select-status-button-right-section {
    gap: 5px;
}

.mobile-multi-select-status-button-right-section-quantity,
.multi-select-status-button-right-section-quantity {
    color: var(--add-media-container-color);
}

.multi-select-status-button-right-section-quantity {
    font-size: 15px;
}

.mobile-multi-select-status-button-right-section-quantity {
    font-size: 14px;
}

.multi-select-status-button-right-section-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}