.mobile-base-topbar-container,
.base-topbar-container {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: var(--welcome-topbar-height);
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    background-color: white;
    z-index: 50;
}

.mobile-base-topbar-container {
    height: var(--mobile-welcome-topbar-height);
}

.mobile-base-topbar-container::after,
.base-topbar-container::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #cccccc;
    position: absolute;
    bottom: 0;
    left: 0;
}

.base-topbar-logo-image {
    width: 125px;
    aspect-ratio: 5 / 2;
    cursor: pointer;
}