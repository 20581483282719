.mobile-shopboard-social-media-post,
.shopboard-social-media-post {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    box-shadow: 0 0 2px 0px #909090;
    border-radius: 10px;
    position: relative;
}

.shopboard-social-media-post-mask {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background-color: #00000042;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
}

.shopboard-social-media-post {
    height: 360px;
    padding: 5px 10px 15px 10px;
}

.mobile-shopboard-social-media-post {
    padding: 2px 10px 10px 10px;
}

.shopboard-social-media-post-top-section,
.mobile-shopboard-social-media-post-top-section {
    display: flex;
    flex-direction: column;
}

.shopboard-social-media-post-top-section {
    gap: 3px;
}

.mobile-shopboard-social-media-post-top-section {
    gap: 2px;
}

.shopboard-social-media-post-visible-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    cursor: pointer;
    visibility: visible !important;
    position: relative;
    z-index: 210;
}

.shopboard-social-media-post-visible-image.invisible {
    fill: white;
}

.mobile-shopboard-social-media-post-image,
.shopboard-social-media-post-image {
    width: 100%;
    aspect-ratio: 1;
    flex-shrink: 0;
}

.mobile-shopboard-social-media-post-tag-products-button,
.shopboard-social-media-post-tag-products-button {
    color: white;
    background-color: #5093f0;
    border-radius: 10px;
    text-align: center;
    padding: 8px 16px;
    cursor: pointer;
    position: relative;
    z-index: 210;
}

.shopboard-social-media-post-tag-products-button {
    font-size: 15px;
    font-weight: 300;
}

.mobile-shopboard-social-media-post-tag-products-button {
    font-size: 14px;
    padding: 5px 16px;
    border-radius: 5px;
}

.shopboard-social-media-post-tag-products-button:active {
    background-color: hsl(209, 97%, 65%);
}