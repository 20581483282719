.drawer-styled-button-list {
    width: 100%;
    background-color: var(--link-item-more-options-menu-background-color);
    border-radius: var(--link-item-more-options-menu-border-radius);
}

.drawer-styled-button-list-item-container {
    width: 100%;
    position: relative;
}

.drawer-styled-button-list-item-container:not(:last-child)::after {
    content: "";
    display: block;
    height: 1px;
    background-color: white;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}

.drawer-styled-button-list-item {
    text-align: center;
    min-width: 90px;
    width: fit-content;
    margin: 0 auto;
    padding: 15px 0;
    border-radius: 0;
    position: relative;
    display: flex;
    gap: 15px;
}

.drawer-styled-button-list-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.drawer-styled-button-list-item:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.drawer-styled-button-list-item:active {
    background-color: hsl(0, 0%, 85%);
}

.drawer-styled-button-list-item-image {
    width: 20px;
    height: 20px;
}