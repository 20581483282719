.add-products-dialog-product-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    padding-bottom: 20px;
}

.add-products-dialog-product-image-frame {
    width: 100%;
    aspect-ratio: 0.9;
    position: relative;
    cursor: pointer;
}

.add-products-dialog-product-selected-v-mark {
    background-color: #427fbe;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    z-index: 96;
    right: 7px;
    bottom: 7px;
    border: 1px solid white;
}

.add-products-dialog-product-image {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 50;
    top: 0;
    left: 0;
    cursor: pointer !important;
}

.add-products-dialog-product-tags {
    position: absolute;
    z-index: 60;
    top: 10px;
    left: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
}

.add-products-dialog-product-draft-tag,
.add-products-dialog-product-out-of-stock-tag {
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 9px;
    padding: 3px 5px;
}


.add-products-dialog-product-draft-tag {
    background-color: #e0f0ff;
    color: #0d5a81;
}

.add-products-dialog-product-out-of-stock-tag {
    color: red;
    background-color: #ffdede;
}

.add-products-dialog-product-details {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
}

.add-products-dialog-product-title {
    font-size: 12px;
    height: 2lh;
}

.add-products-dialog-product-handle {
    font-size: 10px;
    height: 1lh;
    color: grey;
    width: 100%;
}