.colorful-tag {
    padding: 4px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
}

.colorful-tag-bullet {
    width: 10px;
    height: 10px;
    background-color: #555555;
    border-radius: 10px;
}

.colorful-tag-bullet.small {
    width: 7px;
    height: 7px;
}