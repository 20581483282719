.appearance-option-picker-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.appearance-option-picker-option {
    background-color: white;
    width: 60px;
    aspect-ratio: 1.3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.appearance-option-picker-option-text {
    font-size: 13px;
}

.appearance-option-picker-option-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    opacity: 0.7;
}

.appearance-option-picker-option-container.selected {
    opacity: 1;
}