.floating-button-container {
    right: 20px;
    bottom: 20px;
    position: fixed;
    z-index: 200;
}

.floating-button-content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 10px;
    color: white;
}

.floating-button-container-text {
    width: fit-content;
    white-space: nowrap;
    text-transform: none;
}