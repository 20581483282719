.product-item-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.mobile-product-item-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.product-item-image-frame {
    width: 100%;
    position: relative;
}

.product-item-selected-v-mark {
    background-color: #427fbe;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    z-index: 96;
    right: 7px;
    bottom: 7px;
    border: 1px solid white;
}

.product-item-image {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 50;
    top: 0;
    left: 0;
}

.product-item-tags {
    position: absolute;
    z-index: 60;
    top: 10px;
    left: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
}

.product-item-draft-tag,
.product-item-out-of-stock-tag {
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 9px;
    padding: 3px 5px;
}


.product-item-draft-tag {
    background-color: #e0f0ff;
    color: #0d5a81;
}

.product-item-out-of-stock-tag {
    color: red;
    background-color: #ffdede;
}

.product-item-details {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    padding: 5px 0;
}

.product-item-title {
    font-size: 12px;
    width: 100%;
}

.product-item-selling-score-title {
    font-size: 12px;
    font-weight: 450;
}